import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, withModifiers as _withModifiers, createSlots as _createSlots, toHandlers as _toHandlers, withKeys as _withKeys, mergeProps as _mergeProps, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "fw-bold" }
const _hoisted_3 = {
  key: 1,
  class: "mb-4"
}
const _hoisted_4 = { key: 2 }
const _hoisted_5 = {
  key: 0,
  class: "list-unstyled mb-0"
}
const _hoisted_6 = { class: "d-block ms-2 text-body text-break pis-label" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "form-check pe-1" }
const _hoisted_9 = { class: "form-check-label d-flex align-items-start py-1" }
const _hoisted_10 = ["onUpdate:modelValue", "name"]
const _hoisted_11 = ["textContent"]
const _hoisted_12 = { class: "d-flex justify-content-between align-items-center" }
const _hoisted_13 = { class: "text-muted fw-bold" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { class: "fw-bold" }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { class: "fw-bold ext-display-name" }
const _hoisted_18 = {
  key: 0,
  class: "small ext-description"
}
const _hoisted_19 = {
  key: 1,
  class: "small ext-type"
}
const _hoisted_20 = {
  class: "table-responsive pis-scroll position-relative",
  style: {"min-height":"5rem"}
}
const _hoisted_21 = { class: "table table-sm table-striped mt-2" }
const _hoisted_22 = { class: "small" }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = { class: "fw-bold" }
const _hoisted_25 = { key: 0 }
const _hoisted_26 = {
  key: 3,
  class: "d-flex flex-row-reverse"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_ImageGallery = _resolveComponent("ImageGallery")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_Arrow = _resolveComponent("Arrow")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Clickable = _resolveComponent("Clickable")!
  const _component_ScrollableList = _resolveComponent("ScrollableList")!
  const _component_Message = _resolveComponent("Message")!
  const _component_Btn = _resolveComponent("Btn")!
  const _component_LinkSecondary = _resolveComponent("LinkSecondary")!
  const _component_Card = _resolveComponent("Card")!
  const _component_FiltersNavigator = _resolveComponent("FiltersNavigator")!
  const _component_Highlighter = _resolveComponent("Highlighter")!
  const _component_FormInputClearable = _resolveComponent("FormInputClearable")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Loader = _resolveComponent("Loader")!
  const _component_VRuntimeTemplate = _resolveComponent("VRuntimeTemplate")!
  const _component_PisLink = _resolveComponent("PisLink")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_Pagination = _resolveComponent("Pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.prm.userFields)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.prm.userFields, (uf, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "mb-4"
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_Icon, { glyph: "info" }),
                _createTextVNode(" " + _toDisplayString(uf.name), 1)
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(uf.values, (val, valIndex) => {
                return (_openBlock(), _createElementBlock("div", { key: valIndex }, _toDisplayString(val), 1))
              }), 128))
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.productsImages.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_ImageGallery, {
            images: _ctx.productsImages,
            alwaysShowThumbnails: true,
            title: _ctx.t('ProductGallery')
          }, null, 8, ["images", "title"])
        ]))
      : _createCommentVNode("", true),
    (
      _ctx.isFilteringEnabled &&
      ['inline-collapsed', 'inline-expanded'].includes(_ctx.filteringOptions?.filtering || '')
    )
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_Card, {
            class: "mb-4",
            "sticky-footer": "",
            icon: 'filter',
            header: _ctx.t('PrmFilters', true),
            "use-markup": true,
            collapsed: _ctx.filteringOptions?.filtering === 'inline-collapsed'
          }, _createSlots({
            default: _withCtx(() => [
              (_ctx.filtersToDisplay?.length)
                ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filtersToDisplay, (filter) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: filter.attributeCode,
                        class: "pb-2"
                      }, [
                        _createVNode(_component_Clickable, {
                          class: "py-1 d-flex align-items-start text-decoration-none lh-sm",
                          "aria-expanded": _ctx.isFilterOpen(filter),
                          onClick: ($event: any) => (_ctx.toggleFilter(filter))
                        }, {
                          default: _withCtx(() => [
                            (_ctx.isFilterLoading(filter))
                              ? (_openBlock(), _createBlock(_component_Spinner, {
                                  key: 0,
                                  sm: ""
                                }))
                              : (_openBlock(), _createBlock(_component_Arrow, {
                                  key: 1,
                                  dir: _ctx.isFilterOpen(filter) ? 'up' : 'down'
                                }, null, 8, ["dir"])),
                            _createElementVNode("span", _hoisted_6, _toDisplayString(filter.attributeName), 1),
                            _createVNode(_component_Badge, {
                              class: "ms-auto",
                              textContent: _toDisplayString(filter.hitCount)
                            }, null, 8, ["textContent"])
                          ]),
                          _: 2
                        }, 1032, ["aria-expanded", "onClick"]),
                        (_ctx.isFilterOpen(filter))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                              (_ctx.filtersFetched.get(filter.attributeCode)?.length)
                                ? (_openBlock(), _createBlock(_component_ScrollableList, {
                                    key: 0,
                                    class: "list-unstyled my-1 ms-2",
                                    height: "30vh",
                                    items: _ctx.filtersFetched.get(filter.attributeCode) || []
                                  }, {
                                    item: _withCtx(({ item }) => [
                                      _createElementVNode("div", _hoisted_8, [
                                        _createElementVNode("label", _hoisted_9, [
                                          _withDirectives(_createElementVNode("input", {
                                            "onUpdate:modelValue": ($event: any) => ((_ctx.filtersModel[filter.attributeCode][`${item.name}␡${item.code}`]) = $event),
                                            type: "checkbox",
                                            class: "form-check-input mt-1 me-2 flex-shrink-0",
                                            name: item.name,
                                            onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFilterValueChange && _ctx.onFilterValueChange(...args)))
                                          }, null, 40, _hoisted_10), [
                                            [_vModelCheckbox, _ctx.filtersModel[filter.attributeCode][`${item.name}␡${item.code}`]]
                                          ]),
                                          _createElementVNode("span", {
                                            textContent: _toDisplayString(item.name),
                                            class: "text-break user-select-none"
                                          }, null, 8, _hoisted_11),
                                          _createVNode(_component_Badge, {
                                            class: "ms-auto",
                                            textContent: _toDisplayString(item.hitCount)
                                          }, null, 8, ["textContent"])
                                        ])
                                      ])
                                    ]),
                                    _: 2
                                  }, 1032, ["items"]))
                                : (_openBlock(), _createBlock(_component_Message, { key: 1 }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.t('NoFilterValues')), 1)
                                    ]),
                                    _: 1
                                  }))
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128))
                  ]))
                : (_openBlock(), _createBlock(_component_Message, { key: 1 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('NoFilters')), 1)
                    ]),
                    _: 1
                  }))
            ]),
            _: 2
          }, [
            (_ctx.filtersAll?.length)
              ? {
                  name: "footer",
                  fn: _withCtx(() => [
                    (_ctx.areFiltersDirty)
                      ? (_openBlock(), _createBlock(_component_Btn, {
                          key: 0,
                          class: "w-100 mb-2 justify-content-between ext-apply",
                          onClick: _withModifiers(_ctx.searchNow, ["prevent"])
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t('ShowResults', true)), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_LinkSecondary, {
                      icon: "cancel",
                      class: "mt-2 ext-clear",
                      onClick: _withModifiers(_ctx.clearAndCloseFilters, ["prevent"])
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('ClearFilters', true)), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_LinkSecondary, {
                      icon: "pop-out",
                      class: "mt-2 ext-show-all",
                      onClick: _withModifiers(_ctx.showAllFilters, ["prevent"])
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('ShowAllFilters')) + " (" + _toDisplayString(_ctx.filtersAll.length) + ") ", 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032, ["header", "collapsed"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_FiltersNavigator, {
      show: _ctx.isFilteringEnabled && _ctx.allFiltersModalShown,
      title: _ctx.t('AllFiltersModalTitle'),
      t: _ctx.t,
      "filters-all": _ctx.filtersAll,
      "filters-promoted": _ctx.filtersPromoted,
      "filters-additional": _ctx.filtersAdditional,
      "filters-fetched": _ctx.filtersFetched,
      "filters-model": _ctx.filtersModel,
      "fetch-filter-values": _ctx.onFetchFilterValues,
      "filtered-result-count": undefined,
      "filtered-result-count-loading": false,
      onValueChange: _ctx.onModalFilterValueChange,
      onApplyFilters: _ctx.searchNow,
      onClear: _ctx.clearAndCloseFilters
    }, null, 8, ["show", "title", "t", "filters-all", "filters-promoted", "filters-additional", "filters-fetched", "filters-model", "fetch-filter-values", "onValueChange", "onApplyFilters", "onClear"]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", null, [
        _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.totalCount) + " " + _toDisplayString(_ctx.t('Products', true)), 1)
      ]),
      (_ctx.isFilteringEnabled && _ctx.filteringOptions?.filtering === 'popup')
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
            _createVNode(_component_LinkSecondary, {
              icon: "filter",
              onClick: _withModifiers(_ctx.showAllFilters, ["prevent"])
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.t('PrmFilters', true)), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.isSearchEnabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
            _createVNode(_component_Dropdown, {
              open: _ctx.isAutocompleteVisible,
              "onUpdate:open": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isAutocompleteVisible) = $event)),
              "min-width": "200px",
              items: _ctx.autocomplete?.matches
            }, {
              item: _withCtx(({ item }) => [
                _createVNode(_component_Clickable, {
                  class: "d-flex flex-column dropdown-item",
                  onClick: ($event: any) => (_ctx.onSearch(item.text))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_17, [
                      _createVNode(_component_Highlighter, {
                        highlightClassName: "text-primary p-0 display-inline bg-transparent ext-highlight",
                        highlightTag: "span",
                        searchWords: _ctx.highlightTerms,
                        autoEscape: true,
                        textToHighlight: item.text
                      }, null, 8, ["searchWords", "textToHighlight"])
                    ]),
                    (item.description)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                          _createVNode(_component_Highlighter, {
                            highlightClassName: "fw-bold text-primary p-0 display-inline bg-transparent ext-highlight",
                            highlightTag: "span",
                            searchWords: _ctx.highlightTerms,
                            autoEscape: true,
                            textToHighlight: item.description
                          }, null, 8, ["searchWords", "textToHighlight"])
                        ]))
                      : _createCommentVNode("", true),
                    (item.type)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                          _createVNode(_component_Highlighter, {
                            highlightClassName: "fw-bold text-primary p-0 display-inline bg-transparent ext-highlight",
                            highlightTag: "span",
                            searchWords: _ctx.highlightTerms,
                            autoEscape: true,
                            textToHighlight: item.type
                          }, null, 8, ["searchWords", "textToHighlight"])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              activator: _withCtx(({ on }) => [
                _createVNode(_component_FormInputClearable, _mergeProps({
                  modelValue: _ctx.filter,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filter) = $event)),
                  modelModifiers: { trim: true }
                }, _toHandlers(on), {
                  onKeyup: [
                    _cache[2] || (_cache[2] = ($event: any) => (_ctx.search($event))),
                    _withKeys(_ctx.searchNow, ["enter"])
                  ],
                  onChange: _ctx.search,
                  onClear: _ctx.search,
                  placeholder: _ctx.t('SearchProductsInputPlaceholder')
                }), null, 16, ["modelValue", "onKeyup", "onChange", "onClear", "placeholder"])
              ]),
              _: 1
            }, 8, ["open", "items"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_20, [
      _createVNode(_component_Loader, { visible: _ctx.isLoading }, null, 8, ["visible"]),
      _createElementVNode("table", _hoisted_21, [
        _createElementVNode("colgroup", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.table.columns, (col) => {
            return (_openBlock(), _createElementBlock("col", {
              key: col.dataField
            }))
          }), 128)),
          (_ctx.actionTemplate)
            ? (_openBlock(), _createElementBlock("col", {
                key: 0,
                style: _normalizeStyle({ width: _ctx.actionTemplate.columnWidth ?? 'auto' })
              }, null, 4))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.table.columns, (col) => {
              return (_openBlock(), _createElementBlock("th", {
                key: col.dataField
              }, [
                _createElementVNode("span", _hoisted_22, _toDisplayString(col.text), 1)
              ]))
            }), 128)),
            (_ctx.actionTemplate)
              ? (_openBlock(), _createElementBlock("th", _hoisted_23, [
                  (_ctx.actionTemplate.headerTemplate)
                    ? (_openBlock(), _createBlock(_component_VRuntimeTemplate, {
                        key: 0,
                        template: _ctx.actionTemplate.headerTemplate,
                        "template-props": { instance: _ctx.instance, relationship: _ctx.relationship, emit: _ctx.emit, t: _ctx.t }
                      }, null, 8, ["template", "template-props"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row, index) => {
            return (_openBlock(), _createElementBlock("tr", { key: index }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.table.columns, (col) => {
                return (_openBlock(), _createElementBlock("td", {
                  key: col.dataField
                }, [
                  (row && row.values && row.values[col.dataField])
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(row.values[col.dataField], (val, vIndex) => {
                        return (_openBlock(), _createElementBlock("div", { key: vIndex }, [
                          (val.link)
                            ? (_openBlock(), _createBlock(_component_PisLink, {
                                key: 0,
                                instance: _ctx.instance,
                                link: val.link,
                                "clear-search": true,
                                "on-before-click": () => _ctx.saveSettings()
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_24, _toDisplayString(val.link.text), 1)
                                ]),
                                _: 2
                              }, 1032, ["instance", "link", "on-before-click"]))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createTextVNode(_toDisplayString(val.text), 1)
                              ], 64)),
                          (val.tooltip)
                            ? (_openBlock(), _createBlock(_component_Tooltip, {
                                key: 2,
                                text: val.tooltip,
                                placement: "right"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_Icon, {
                                    glyph: "info",
                                    class: "text-primary ms-1"
                                  })
                                ]),
                                _: 2
                              }, 1032, ["text"]))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128))
                    : _createCommentVNode("", true)
                ]))
              }), 128)),
              (_ctx.actionTemplate?.cellTemplate)
                ? (_openBlock(), _createElementBlock("td", _hoisted_25, [
                    _createVNode(_component_VRuntimeTemplate, {
                      template: _ctx.actionTemplate.cellTemplate,
                      "template-props": { instance: _ctx.instance, relationship: _ctx.relationship, row, emit: _ctx.emit, t: _ctx.t }
                    }, null, 8, ["template", "template-props"])
                  ]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ])
      ])
    ]),
    (_ctx.pageCount > 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
          _createVNode(_component_Pagination, {
            total: _ctx.pageCount,
            current: _ctx.page,
            onChange: _ctx.onPageChange
          }, null, 8, ["total", "current", "onChange"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}