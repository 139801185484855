/* eslint-disable quotes */
import {
  CatalogAppSettings,
  CatalogDetail,
  CatalogItem,
  CatalogProductItem,
} from '@/common/services/swagger/index.defs';
import { IComponentOptions } from '../runtime/IComponentOptions';
import { IOptions } from '../runtime/IOptions';
import { DisplayMode } from '../runtime/IRouteData';
import { IPricingExportOptions } from './IPricingExportOptions';
import { CatalogAction } from '../runtime/CatalogAction';
import { CatalogProductSwitchDisplayModeOption } from './components/ICatalogProductSwitchOptions';

export const InternalMenuActions: Record<keyof typeof CatalogAction, string> = Object.entries(
  CatalogAction,
).reduce(
  (result, [name, value]) => ({
    ...result,
    [name]: `internal:${value}`,
  }),
  {} as Record<keyof typeof CatalogAction, string>,
);

export class DefaultOptions implements IOptions {
  baseUrl = process.env.VUE_APP_API_PATH;

  application: CatalogAppSettings = {
    appCode: '',
    langCode: 'en',
  };

  export: IPricingExportOptions = {
    pricesEnabled: false,
  };

  styles = [
    {
      url: `${process.env.VUE_APP_STYLE_PATH}/pis-products-theme.css?${process.env.VUE_APP_BUILD}`,
    },
    { url: `${process.env.VUE_APP_STYLE_PATH}/pis-products.css?${process.env.VUE_APP_BUILD}` },
  ];

  components: IComponentOptions = {
    actions: {
      catalogListMenu: {
        visible: true,
        items: [
          {
            text: 'New Catalog',
            code: InternalMenuActions.createNew,
            visible: true,
            itemTemplate: "{{ t('NewCatalog') }}",
          },
          {
            text: 'Import/Export History',
            code: InternalMenuActions.history,
            visible: true,
            itemTemplate: "{{ t('ImportExportHistory') }}",
          },
        ],
      },
      catalogItemMenu: {
        visible: true,
        items: [
          {
            text: 'Favorites',
            code: InternalMenuActions.makeDefault,
            visible: (item: CatalogItem) => item.editable && !item.default,
            itemTemplate: "{{ t('MarkAsFavorite') }}",
          },
          {
            text: 'Favorites',
            code: InternalMenuActions.unmakeDefault,
            visible: (item: CatalogItem) => item.editable && item.default,
            itemTemplate: "{{ t('RemoveFromFavorites') }}",
          },
          {
            text: 'Edit',
            code: InternalMenuActions.edit,
            visible: (item: CatalogItem) => item.editable,
            itemTemplate: "{{ t('Edit') }}",
          },
          {
            text: 'Copy',
            code: InternalMenuActions.copy,
            visible: true,
            itemTemplate: "{{ t('Copy') }}",
          },
          {
            text: 'Import',
            code: InternalMenuActions.import,
            visible: (item: CatalogItem) => item.editable,
            itemTemplate: "{{ t('Import') }}",
          },
          {
            text: 'Export',
            code: InternalMenuActions.export,
            visible: true,
            itemTemplate: "{{ t('Export') }}",
          },
          {
            text: 'Delete',
            code: InternalMenuActions.delete,
            visible: (item: CatalogItem) => item.editable,
            itemTemplate: "{{ t('Delete') }}",
          },
        ],
      },
      productListMenu: { visible: false },
      productItemMenu: {
        visible: true,
        items: [
          {
            text: 'Edit',
            code: InternalMenuActions.productEdit,
            visible: (item: CatalogProductItem) =>
              !!item.catalogs?.every((catalog: CatalogDetail) => catalog.editable),
            itemTemplate: " {{ t('Edit') }}",
          },
          {
            text: 'Copy',
            code: InternalMenuActions.productCopy,
            visible: true,
            itemTemplate: " {{ t('Copy') }}",
          },
          {
            text: 'Delete',
            code: InternalMenuActions.productDelete,
            visible: (item: CatalogProductItem) =>
              !!item.catalogs?.every((catalog: CatalogDetail) => catalog.editable),
            itemTemplate: " {{ t('Delete') }}",
          },
        ],
      },
      aliasListMenu: {
        visible: true,
        items: [
          {
            text: 'Import',
            code: InternalMenuActions.aliasImport,
            visible: true,
            itemTemplate: "{{ t('Import') }}",
          },
          {
            text: 'Export',
            code: InternalMenuActions.aliasExport,
            visible: true,
            itemTemplate: "{{ t('Export') }}",
          },
        ],
      },
      aliasItemMenu: {
        visible: true,
        items: [
          {
            code: InternalMenuActions.aliasEdit,
            visible: true,
            icon: 'edit',
          },
          {
            code: InternalMenuActions.aliasDelete,
            icon: 'trash',
            visible: (item: CatalogProductItem) => !!item.aliases?.length,
          },
        ],
      },
    },
    search: {},
    searchForm: {
      visible: true,
      useAutocomplete: true,
    },
    searchToolbar: {
      pageSizes: [10, 20, 50, 100],
      visible: true,
    },
    productsSearchToolbar: {
      visible: true,
      pageSizes: [12, 24, 48, 72],
      displayModes: [DisplayMode.table, DisplayMode.list, DisplayMode.grid],
    },
    searchHeader: {
      visible: true,
    },
    searchProductCategories: {
      visible: true,
      card: true,
      icon: true,
      maxCategoriesVisible: 12,
    },
    searchPartCategories: {
      visible: true,
      card: true,
      icon: true,
      maxCategoriesVisible: 12,
    },
    searchFilters: {
      visible: true,
      card: true,
      icon: true,
      maxFiltersVisible: 10,
      expandedFromNodeLevel: 2,
      visibleFromNodeLevel: 0,
      //  collapseAllVisible: true,
      //  expandAllVisible: true,
    },
    searchFiltersStatus: {
      visible: true,
    },
    searchAliasFilters: {
      visible: true,
    },
    catalogsTable: {
      visible: true,
      columns: [
        {
          headerTemplate: '{{ t("ColumnCode") }}',
          width: '33%',
          dataField: 'code',
        },
        {
          dataField: 'description',
          headerTemplate: '{{ t("ColumnDescription") }}',
          cellTemplate: `
            <span>{{ item.description }}</span>
            <div v-if="item.shared" class="text-muted small">
              <span>{{ t('SharedCatalogEditInSourceSystem') }}</span>
            </div>
          `,
        },
        {
          headerTemplate: '{{ t("ColumnLastModified") }}',
          dataField: 'dateModified',
          width: '140px',
          cellTemplate:
            '{{ new Date(item["dateModified"]).toLocaleString([],{dateStyle:"medium"}) }}',
        },
      ],
    },
    itemsTable: {
      selectable: false,
      detailsVisible: true,
      visible: true,
      columns: [
        {
          caption: 'Name',
          dataField: '#DisplayName',
          width: '33%',
          headerTemplate: '{{ t("ProductListProduct",true) }}',
          cellTemplate: `
            <a v-if="!item.inactive" href class="fw-bold" @click.prevent="details(item)">
              {{ attrValue(item, "#DisplayName") }}
            </a>
            <span v-else class="fw-bold text-muted">{{ attrValue(item, "#DisplayName") }}</span>
            <div class="text-muted small">
              <em v-if="item.productType == catalogProductType.Configured">
                {{ t('ConfiguredProducts') }}<br />
              </em>
              <em v-if="item.customerClassification">
                {{ t('CustomerClassification') }}:&nbsp;{{ item.customerClassification }}<br />
              </em>
              <em v-if="item.baseProduct">
              {{ t('BaseProductId') }}:&nbsp;
              <Clickable
                v-if="!item.baseProduct.inactive"
                @click="baseProductDetails(item, item.baseProduct)"
              >
                {{ item.baseProduct.productId }}
              </Clickable>
              <span v-else class="fw-bold text-muted">
                {{ item.baseProduct.productId }}
              </span>
              <br />
            </em>
            </div>
          `,
        },
        {
          caption: 'Description',
          headerTemplate: '{{ t("ProductListInformation",true) }}',
          dataField: '#Description',
        },
        {
          headerTemplate: '{{ t("ColumnLastModified") }}',
          dataField: 'dateModified',
          width: '140px',
          cellTemplate:
            '{{ new Date(item["dateModified"]).toLocaleString([],{dateStyle:"medium"}) }}',
        },
      ],
    },
    itemsList: {
      imageSize: '10rem',
      visible: true,
      navigateOnContentClick: true,
      imagePadding: 0,
      selectable: false,
      detailsVisible: true
    },
    itemsGrid: {
      visible: true,
      navigateOnContentClick: true,
      selectable: false,
      detailsVisible: true,
    },
    itemsGridHorizontal: {
      visible: true,
      navigateOnContentClick: true,
      selectable: false,
      detailsVisible: true,
      carousel: {
        mouseDrag: true,
        touchDrag: true,
        transition: 300,
        wrapAround: false,
        defaults: {
          itemsToShow: 1,
          itemsToScroll: 1,
          snapAlign: 'center',
          margin: 5,
          navigationVisible: true,
          paginationVisible: true,
        },
        breakpoints: {
          576: {
            itemsToShow: 2,
            itemsToScroll: 1,
            snapAlign: 'start',
            margin: 2,
            navigationVisible: true,
            paginationVisible: false,
          },
          922: {
            itemsToShow: 4,
            itemsToScroll: 1,
            snapAlign: 'start',
            margin: 2,
            navigationVisible: true,
            paginationVisible: false,
          },
        },
      },
    },
    preview: {
      visible: true,
    },
    breadcrumbs: {
      visible: true,
    },
    aliasesTable: {
      selectable: false,
      visible: true,
      columns: [
        {
          caption: 'Name',
          dataField: '#DisplayName',
          headerTemplate: '{{ t("ProductListProduct",true) }}',
          cellTemplate: `
            <a v-if="item.productType === CatalogProductTypeEnum.Listed && !item.inactive" href class="fw-bold" @click.prevent="details(item)">
              {{ attrValue(item, "#DisplayName") }}
            </a>
            <span v-else class="fw-bold text-muted">{{ attrValue(item, "#DisplayName") }}</span>
            <div v-if="item.productType == catalogProductType.Configured" class="text-muted small">
              <em>{{ t('ConfiguredProducts') }}</em>
            </div>
        `,
        },
        {
          caption: 'Description',
          headerTemplate: '{{ t("ProductListInformation",true) }}',
          dataField: '#Description',
        },
        {
          caption: 'Aliases',
          headerTemplate: '{{ t("Aliases") }}',
          cellTemplate: `
          <template v-if="item.aliases">
            <div v-for="alias in item.aliases" class="text-bold">
              {{ alias.value }}
            </div>
          </template>
        `,
        },
        {
          headerTemplate: '{{ t("ColumnLastModified") }}',
          dataField: 'dateModified',
          width: '140px',
          cellTemplate:
            '{{ item["dateModified"] ? new Date(item["dateModified"]).toLocaleString([],{dateStyle:"medium"}) : "" }}',
        },
      ],
    },
    selection: {
      visible: false,
      deselectAllVisible: true,
      selectAllVisible: true,
      persist: true,
      countVisible: true,
    },
    catalogProductSwitch: {
      defaultDisplayMode: CatalogProductSwitchDisplayModeOption.catalog,
      displayModes: [
        CatalogProductSwitchDisplayModeOption.catalog,
        CatalogProductSwitchDisplayModeOption.product,
      ],
    },
  };
  allowMobileView = true;
  cacheTranslations = true;
}
