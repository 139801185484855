import { AppSettings, TreeTypeEnum } from '@/common/services/swagger/index.defs';
import { IComponentOptions } from '../runtime/IComponentOptions';
import { IOptions } from '../runtime/IOptions';
import { DisplayMode } from '../runtime/IRouteData';

export class DefaultOptions implements IOptions {
  baseUrl = process.env.VUE_APP_API_PATH;

  application: AppSettings = {
    appCode: '',
    langCode: 'en',
    countryCode: undefined,
    internalUser: false,
    treeType: TreeTypeEnum.Products,
    anonymousUser: false,
  };

  styles = [
    {
      url: `${process.env.VUE_APP_STYLE_PATH}/pis-products-theme.css?${process.env.VUE_APP_BUILD}`,
    },
    { url: `${process.env.VUE_APP_STYLE_PATH}/pis-products.css?${process.env.VUE_APP_BUILD}` },
  ];

  components: IComponentOptions = {
    common: {
      hideDocumentLinks: true,
      hideReplacements: false,
      hideWhereUsed: false,
      maxAttributeValuesVisible: 10,
    },
    search: {},
    searchForm: {
      visible: true,
      useAutocomplete: true,
      maxCategoryMatches: 2,
    },
    searchToolbar: {
      visible: true,
      pageSizes: [12, 24, 48, 72],
      displayModes: [DisplayMode.table, DisplayMode.list, DisplayMode.grid],
      countVisible: true,
      displayModesVisible: true,
      pageSizesVisible: true,
      sortVisible: true,
    },
    searchPagination: {
      visible: true,
      visiblePages: 5,
    },
    searchHeader: {
      visible: true,
    },
    searchCategories: {
      visible: true,
      card: true,
      icon: true,
      maxCategoriesVisible: 12,
    },
    searchFilters: {
      visible: true,
      card: true,
      icon: true,
      maxFiltersVisible: 10,
      visibleFromNodeLevel: 0,
      //expandAllVisible: true,
      //collapseAllVisible: true,
    },
    searchFiltersStatus: {
      visible: true,
    },
    searchNarrowByProduct: {
      visible: true,
      card: true,
      icon: true,
    },
    configurators: {
      visible: false,
    },
    relatedLinks: {
      visible: false,
      showImages: true,
    },
    downloads: {
      visible: true,
      count: undefined,
      loading: false,
    },
    itemsTable: {
      visible: true,
      selectable: false,
      columns: [
        {
          caption: 'Name',
          dataField: '#DisplayName',
          width: '33%',
          treeTypes: [TreeTypeEnum.Products],
          showAllIdentifiers: true,
          headerTemplate: '{{ t("ProductListProduct",true) }}',
          cellTemplate: `
          <template v-if="item.inactive">
            <span class="text-muted">{{ attrValue(item, "#DisplayName") }}</span>
          </template>
          <template v-else>
            <a href class="fw-bold" :class="{'text-muted':item.inactive}" @click.prevent="details(item)">
              {{ attrValue(item, "#DisplayName") }}
            </a>
          </template>
          `,
        },
        {
          caption: 'Name',
          dataField: '#DisplayName',
          width: '33%',
          treeTypes: [TreeTypeEnum.Parts],
          showAllIdentifiers: true,
          headerTemplate: '{{ t("ProductListSparePart",true) }}',
          cellTemplate: `
          <template v-if="item.inactive">
            <span class="text-muted">{{ attrValue(item, "#DisplayName") }}</span>
          </template>
          <template v-else>
            <a href class="fw-bold" :class="{'text-muted':item.inactive}" @click.prevent="details(item)">
              {{ attrValue(item, "#DisplayName") }}
            </a>
          </template>
          `,
        },

        {
          caption: 'Description',
          treeTypes: [TreeTypeEnum.Products, TreeTypeEnum.Parts],
          headerTemplate: '{{ t("ProductListInformation",true) }}',
          dataField: 'CatalogDescription',
          showReplacements: true,
        },
        {
          caption: 'Quantity',
          treeTypes: [TreeTypeEnum.Parts],
          headerTemplate: '{{ t("ProductListQuantity",true) }}',
          dataField: '#Quantity',
          masterProductRequired: true,
        },
      ],
    },
    itemsList: {
      visible: true,
      showAllIdentifiers: true,
      showReplacements: true,
      navigateOnContentClick: true,
      imageSize: '10rem',
      selectable: false,
      imagePadding: 0,
      mobileBreakpoint: 680,
      imageLazyLoading: true,
    },
    itemsGrid: {
      visible: true,
      selectable: false,
      showAllIdentifiers: true,
      showReplacements: true,
      navigateOnContentClick: true,
      imageLazyLoading: true,
    },
    itemsGridHorizontal: {
      visible: true,
      selectable: false,
      showAllIdentifiers: true,
      showReplacements: true,
      navigateOnContentClick: true,
      carousel: {
        mouseDrag: true,
        touchDrag: true,
        transition: 300,
        wrapAround: false,
        defaults: {
          itemsToShow: 1,
          itemsToScroll: 1,
          snapAlign: 'center',
          margin: 5,
          navigationVisible: true,
          paginationVisible: true,
        },
        breakpoints: {
          576: {
            itemsToShow: 2,
            itemsToScroll: 1,
            snapAlign: 'start',
            margin: 2,
            navigationVisible: true,
            paginationVisible: false,
          },
          922: {
            itemsToShow: 4,
            itemsToScroll: 1,
            snapAlign: 'start',
            margin: 2,
            navigationVisible: true,
            paginationVisible: false,
          },
        },
      },
    },
    preview: {
      visible: true,
    },
    detailsClassifications: {
      visible: true,
      treeView: false,
    },
    detailsGallery: {
      visible: true,
      hideThumbnailImages: false,
      showOverlayArrows: false,
    },
    details: {
      actionTemplate: undefined,
      attributeCodes: undefined,
      bottomNavigationSticky: true,
      hideBottomNavigation: false,
      showTabCounts: true,
      showTabIcons: true,
      relationships: {
        pageSize: 5,
        searchMinItems: 5,
        filteringMinItems: 5,
        filteringMaxAttributesVisible: 5,
        actionTemplates: {},
      },
    },
    detailsHeader: {
      visible: true,
      showBackBtn: true,
      showFeedbackBtn: false,
      showPrintToPdfBtn: true,
      showPrintViewBtn: false,
    },
    sparePartTypes: {
      visible: true,
    },
    breadcrumbs: {
      visible: true,
    },
    exportMenu: {
      visible: false,
      items: [],
    },
    favoriteCategories: {
      visible: false,
      card: true,
      icon: true,
      productCids: undefined,
      partCids: undefined,
      maxCategoriesVisible: 12,
    },
    favoriteViews: {
      visible: false,
      card: true,
      icon: true,
      fallbackCode: 'ABB',
      maxViewsVisible: 10,
      savedViewIds: undefined,
    },
    attributesTable: {
      visible: true,
    },
    compare: {
      visible: true,
      imageSize: '120px',
      showAllIdentifiers: true,
      showReplacements: true,
      categoriesVisible: true,
      navigationVisible: true,
      printButtonVisible: false,
      compactMode: true,
      attrColWidth: '240px',
      valColWidth: '240px',
      imageVisible: true,
      imageHideOnScroll: true,
    },
    selection: {
      visible: false,
      deselectAllVisible: true,
      selectAllVisible: true,
      persist: false,
      countVisible: true,
    },
  };
  allowMobileView = true;
  cacheTranslations = true;
}
