import {
  CatalogExportPriceSettings,
  CatalogProductSearchTypeEnum,
  CatalogProductSortFieldEnum,
  CatalogSearchTypeEnum,
  CatalogSortFieldEnum,
  JobSearchResult,
  JobSortSettings,
  SortDirectionEnum,
  CatalogItem,
  FilterSettings,
  CatalogProductUpdateItem,
  CatalogProductDeleteItem,
  CatalogActionResult,
  TreeTypeEnum,
  CatalogOptionsResult,
  CatalogProductItem,
  CatalogTypeEnum,
  ConfigurationItem,
  Catalog,
  AliasesEnum,
  AliasAddItem,
  AliasDeleteItem,
  ActionResult,
  AliasActionResult,
  ExportActionResult,
  CatalogExportActionResult,
  ProductItem,
} from '@/common/services/swagger/index.defs';

import { Instance } from '../Instance';
import { DisplayMode, IRouteData } from './IRouteData';
import _cloneDeep from 'lodash/cloneDeep';
import _omit from 'lodash/omit';
import { ICommand } from '@/common/api/runtime/ICommand';
import { downloadFile } from '@/common/helpers/downloadFile';
import { INotification, NotificationType } from '@/common/api/runtime/INotification';
import {
  AliasCreateNewActionData,
  AliasDeleteActionData,
  AliasEditActionData,
  AliasExportActionData,
  AliasImportActionData,
  CatalogAnyActionData,
  CatalogCopyActionData,
  CatalogDeleteActionData,
  CatalogEditActionData,
  CatalogErrorsActionData,
  CatalogExportActionData,
  CatalogHistoryActionData,
  CatalogImportActionData,
  CatalogNewActionData,
  CatalogProductCopyActionData,
  CatalogProductDeleteActionData,
  CatalogProductEditActionData,
} from './CatalogActionData';
import { CatalogAction } from './CatalogAction';
import { IMenuItem } from '../configuration/components/IExportMenuOptions';
import { IInstance } from '@/common/api/runtime/IInstance';

const executePlaceholder = (name: string) => {
  alert(`Command "${name}" should be handled by application`);
  return Promise.resolve();
};

const getProductsSortDirection = (
  currentSortField: CatalogProductSortFieldEnum,
): SortDirectionEnum => {
  return currentSortField === CatalogProductSortFieldEnum.LastModified
    ? SortDirectionEnum.Descending
    : SortDirectionEnum.Ascending;
};

export abstract class Command<TArgs, ReturnType = unknown>
implements ICommand<Instance, ReturnType>
{
  isHandled = false;
  constructor(public args: TArgs) {}
  abstract execute: (instance: Instance) => Promise<ReturnType | undefined>;
  abstract name: string;
}

export class DetailsCommand extends Command<ProductItem> {
  name = 'details';

  public execute = async (): Promise<unknown> => {
    return executePlaceholder(this.name);
  };
}

export class SearchCommand extends Command<{
  searchText: string;
  searchType: CatalogSearchTypeEnum;
}> {
  name = 'catalogs-filter-search';

  public execute = async (instance: Instance): Promise<unknown> => {
    const routeData = {
      view: 'catalogs',
      catalogs: {
        searchType: this.args.searchType,
        searchText: this.args.searchText,
        pagination: { page: 1 },
      },
    } as IRouteData;

    return await instance.router?.setRoute(routeData);
  };
}

export interface SearchProductsCommandArgs {
  searchText: string;
  searchType: CatalogProductSearchTypeEnum;
  resetCategories?: boolean;
  resetFilters?: boolean;
}

export class SearchProductsCommand extends Command<SearchProductsCommandArgs> {
  name = 'products-filter-search';

  public execute = async (instance: Instance): Promise<unknown> => {
    const routeData: IRouteData = {
      ...instance.router.routeData,
      products: {
        ...instance.router.routeData.products,
        searchType: this.args.searchType,
        searchText: this.args.searchText,
        pagination: { ...instance.router.routeData.products.pagination, page: 1 },
      },
    };

    if (this.args.resetCategories) {
      routeData.products.cid = undefined;
      routeData.products.treeType = undefined;
    }

    if (this.args.resetFilters) {
      routeData.products.filters = undefined;
    }

    return await instance.router?.setRoute(routeData);
  };
}

const reloadCatalogs = async (instance: Instance, all = true) =>
  instance.router?.setRoute(<IRouteData>{
    ...instance.router?.routeData,
    view: 'catalogs',
    products: {
      ...instance.router?.routeData.products,
      aliases: undefined,
    },
    catalogs: {
      ...instance.router?.routeData.catalogs,
      searchType: all ? undefined : instance.router?.routeData.catalogs.searchType,
      searchText: all ? undefined : instance.router?.routeData.catalogs.searchText,
      pagination: { page: 1 },
    },
  });

const reloadProducts = async (instance: Instance, all = false) => {
  /** Check if sort field is allowed */
  let sortField: CatalogProductSortFieldEnum | undefined =
    instance.router.routeData.view === 'products'
      ? instance.router?.routeData.products.sortField
      : CatalogProductSortFieldEnum.LastImport;
  if (
    !sortField ||
    ![
      CatalogProductSortFieldEnum.LastImport,
      CatalogProductSortFieldEnum.LastModified,
      CatalogProductSortFieldEnum.Product,
    ].includes(sortField)
  ) {
    sortField = CatalogProductSortFieldEnum.LastImport;
  }
  return instance.router?.setRoute(
    <IRouteData>{
      ...instance.router?.routeData,
      view: 'products',
      products: {
        ...instance.router?.routeData.products,
        sortField,
        sortDirection: getProductsSortDirection(sortField),
        searchText: undefined,
        cid: all ? undefined : instance.router?.routeData.products.cid,
        filters: undefined,
        catalogId: all ? undefined : instance.router?.routeData.products.catalogId,
        pagination: { page: 1 },
        aliases: undefined,
      },
    },
    true,
  );
};

const reloadAliases = async (instance: Instance, all = false) => {
  const sortField: CatalogProductSortFieldEnum =
    (instance.router.routeData.view === 'aliases'
      ? instance.router.routeData.products.sortField
      : CatalogProductSortFieldEnum.Alias) || CatalogProductSortFieldEnum.Alias;
  return instance.router?.setRoute(
    <IRouteData>{
      ...instance.router?.routeData,
      view: 'aliases',
      products: {
        ...instance.router?.routeData.products,
        searchText: undefined,
        cid: all ? undefined : instance.router?.routeData.products.cid,
        filters: undefined,
        catalogId: all ? undefined : instance.router?.routeData.products.catalogId,
        pagination: { page: 1 },
        aliases: all ? AliasesEnum.All : instance.router.routeData.products.aliases,
        sortField,
        sortDirection: getProductsSortDirection(sortField),
      },
    },
    true,
  );
};

type CatalogAnyActionResult = {
  success?: CatalogActionResult['success'];
  message?: CatalogActionResult['message'];
  errors?: CatalogActionResult['errors'];
  warnings?: CatalogActionResult['warnings'];
};

interface CatalogAnyActionCommandArgs {
  onSuccessMessage: string;
  onErrorMessage?: string;
}

const handleResponse = async <R extends CatalogAnyActionResult>(
  instance: Instance,
  args: CatalogAnyActionCommandArgs,
  result: Promise<R | undefined> | undefined,
): Promise<R | undefined> | never => {
  if (!result) {
    instance.store.data.isLoadingData = false;
    return;
  }
  try {
    instance.store.data.isLoadingData = true;
    const response: R | undefined = await result;
    instance.store.data.isLoadingData = false;
    if (response?.errors?.length || response?.warnings?.length) {
      await instance.execute(
        new CatalogErrorsActionCommand({
          errors: response.errors || [],
          warnings: response.warnings || [],
        }),
      );
      return;
    } else if (response && response.success === true) {
      await instance.execute(
        new AddNotificationCommand({
          type: NotificationType.success,
          message: args.onSuccessMessage,
        }),
      );
    } else {
      throw new Error(response?.message || 'Unknown error');
    }
    if (response && response.success === true) {
      return response as R;
    }
  } catch (error) {
    instance.store.data.isLoadingData = false;
    await instance.execute(
      new AddNotificationCommand({ type: NotificationType.danger, message: error.message }),
    );
    throw error;
  }
};

export class CatalogsCommand extends Command<void> {
  name = 'catalogs';

  public execute = async (instance: Instance): Promise<unknown> => reloadCatalogs(instance, false);
}

export class ProductsCommand extends Command<void> {
  name = 'products';

  public execute = async (instance: Instance): Promise<unknown> => reloadProducts(instance, true);
}

export class AliasesCommand extends Command<void> {
  name = 'aliases';

  public execute = async (instance: Instance): Promise<unknown> => reloadAliases(instance, true);
}

export class AliasesReloadCommand extends Command<void> {
  name = 'aliases-reload';

  public execute = async (instance: Instance): Promise<unknown> => reloadAliases(instance, false);
}

export class AliasesFilterCommand extends Command<{ aliasFilter?: AliasesEnum }> {
  name = 'aliases-filter';

  public execute = async (instance: Instance): Promise<unknown> => {
    return await instance.router?.setRoute({
      products: {
        aliases: this.args.aliasFilter,
      },
    } as IRouteData);
  };
}

export class CatalogProductsCommand extends Command<{ catalogId: string }> {
  name = 'catalog-products';

  constructor(args: { catalogId: string }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<unknown> => {
    return await instance.router?.setRoute({
      view: 'products',
      products: {
        searchText: undefined,
        cid: undefined,
        filters: undefined,
        catalogId: this.args.catalogId,
        pagination: {
          page: 1,
          pageSize: instance.getRouteData().products.pagination.pageSize,
        },
      },
    } as IRouteData);
  };
}

export class ClassificationCommand extends Command<{
  cid: string;
  treeType?: TreeTypeEnum;
  clearSearch: boolean;
}> {
  name = 'products-filter-classification';

  constructor(args: { cid: string; treeType?: TreeTypeEnum; clearSearch: boolean }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<unknown> => {
    const route = instance.getRouteData();

    //  route.view = 'products';

    route.products.pagination.page = 1;
    route.products.cid = this.args.cid;
    route.products.treeType = this.args.treeType;

    if (this.args.clearSearch) {
      route.products.searchText = undefined;
    }

    return await instance.router?.setRoute(route);
  };
}

export class PreviewCommand extends Command<{ productId: string }> {
  name = 'product-preview';

  constructor(args: { productId: string }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<unknown> => {
    return await instance.httpService?.preview(this.args.productId);
  };
}

export class ApplyFiltersCommand extends Command<{
  filters: FilterSettings[];
}> {
  name = 'products-filter-parametric-apply';

  constructor(args: { filters: FilterSettings[] }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<unknown> => {
    return await instance.router?.setRoute({
      view: 'products',
      products: {
        filters: this.args.filters,
        pagination: {
          page: 1,
          pageSize: instance.router.routeData.products.pagination.pageSize,
        },
      },
    } as IRouteData);
  };
}

export class ExcludeFilterCommand extends Command<{
  attributeCode: string;
  value: string;
}> {
  name = 'products-filter-parametric-remove';

  constructor(args: { attributeCode: string; value: string }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<unknown> => {
    const filters = instance.router.routeData?.products.filters
      ?.map(
        ({ attributeCode, values }): FilterSettings => ({
          attributeCode,
          values: values?.filter((val) => `${val.name}␡${val.code}` !== this.args.value),
        }),
      )
      .filter(({ values }) => values?.length);

    return await instance.router?.setRoute({
      products: {
        filters,
        pagination: {
          page: 1,
          pageSize: instance.router.routeData.products.pagination.pageSize,
        },
      },
    } as IRouteData);
  };
}

export class GetFilterValuesCommand extends Command<{ attributeCodes: string[] }> {
  name = 'products-get-filter-values';

  constructor(args: { attributeCodes: string[] }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<unknown> => {
    const routeDate = instance.getRouteData();

    return await instance.httpService?.filterValues(
      this.args.attributeCodes,
      routeDate.products.catalogId,
    );
  };
}

export class ProductsDisplayModeCommand extends Command<{ mode: DisplayMode }> {
  name = 'products-display-mode';

  constructor(args: { mode: DisplayMode }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<unknown> => {
    return await instance.router?.setRoute({
      products: { displayMode: this.args.mode },
    } as IRouteData);
  };
}

export class ProductsPageSizeCommand extends Command<{ pageSize: number }> {
  name = 'products-page-size';

  constructor(args: { pageSize: number }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<unknown> => {
    return await instance.router?.setRoute({
      products: {
        pagination: {
          pageSize: this.args.pageSize,
          page: 1,
        },
      },
    } as IRouteData);
  };
}

export class CatalogsPageSizeCommand extends Command<{ pageSize: number }> {
  name = 'catalogs-page-size';

  constructor(args: { pageSize: number }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<unknown> => {
    return await instance.router?.setRoute({
      catalogs: {
        pagination: {
          pageSize: this.args.pageSize,
          page: 1,
        },
      },
    } as IRouteData);
  };
}

export class ProductsSortByCommand extends Command<{ sortField: CatalogProductSortFieldEnum }> {
  name = 'products-sort-by';

  constructor(args: { sortField: CatalogProductSortFieldEnum }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<unknown> => {
    return await instance.router?.setRoute({
      products: {
        sortField: this.args.sortField,
        sortDirection: getProductsSortDirection(this.args.sortField),
      },
    } as IRouteData);
  };
}

export class ProductsPaginateCommand extends Command<{ page: number }> {
  name = 'products-paginate';

  constructor(args: { page: number }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<unknown> => {
    return await instance.router?.setRoute({
      products: {
        pagination: {
          page: this.args.page,
        },
      },
    } as IRouteData);
  };
}

export class CatalogsPaginateCommand extends Command<{ page: number }> {
  name = 'catalogs-paginate';

  constructor(args: { page: number }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<unknown> => {
    return await instance.router?.setRoute({
      catalogs: {
        pagination: {
          page: this.args.page,
        },
      },
    } as IRouteData);
  };
}

export class UpdateProductCommand extends Command<
  {
    product: CatalogProductUpdateItem;
    catalogId: string;
  } & CatalogAnyActionCommandArgs
> {
  name = 'catalog-update-product';

  public execute = async (instance: Instance): Promise<any> =>
    handleResponse(
      instance,
      this.args,
      instance.httpService?.updateCatalogProduct(this.args.product, this.args.catalogId),
    );
}

export interface CopyProductCommandArgs extends CatalogAnyActionCommandArgs {
  sourceCatalogId: string;
  sourceQuantityIds: string[];
  targetCatalogId: string;
}
export class CopyProductCommand extends Command<CopyProductCommandArgs> {
  name = 'catalog-copy-product';

  public execute = async (instance: Instance): Promise<any> => {
    await handleResponse(
      instance,
      this.args,
      instance.httpService?.copyCatalogProduct(
        this.args.sourceCatalogId,
        this.args.sourceQuantityIds,
        this.args.targetCatalogId,
      ),
    );
    return reloadProducts(instance);
  };
}

export interface CopyProductToNewCommandArgs extends CatalogAnyActionCommandArgs {
  sourceCatalogId: string;
  sourceQuantityIds: string[];
  code: string;
  type: CatalogTypeEnum;
  description: string;
}
export class CopyProductToNewCommand extends Command<CopyProductToNewCommandArgs> {
  name = 'catalog-copy-product';

  public execute = async (instance: Instance): Promise<any> => {
    await handleResponse(
      instance,
      this.args,
      instance.httpService?.copyCatalogProductToNew(
        this.args.sourceCatalogId,
        this.args.sourceQuantityIds,
        this.args.code, 
        this.args.type, 
        this.args.description
      ),
    );
    return reloadProducts(instance);
  };
}

export class CatalogsSortByCommand extends Command<{ sortField: CatalogSortFieldEnum }> {
  name = 'catalogs-sort-by';

  constructor(args: { sortField: CatalogSortFieldEnum }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<unknown> => {
    const newRoute = {
      catalogs: {
        sortField: this.args.sortField,
        sortDirection:
          this.args.sortField == CatalogSortFieldEnum.LastModified
            ? SortDirectionEnum.Descending
            : SortDirectionEnum.Ascending,
      },
    } as IRouteData;

    return await instance.router?.setRoute(newRoute);
  };
}

export class CatalogsFilterByDateCommand extends Command<{ days?: number | string | undefined }> {
  name = 'catalogs-filter-by-date';

  constructor(args: { days?: number | string | undefined }) {
    super(args);
  }

  public execute = async (instance: Instance): Promise<unknown> => {
    const newRoute = {
      catalogs: {
        createdWithinLastDays: this.args.days,
      },
    } as IRouteData;

    return await instance.router?.setRoute(newRoute);
  };
}

export class DownloadJobHistoryCommand extends Command<
  { sortSettings?: JobSortSettings },
  JobSearchResult | undefined
> {
  name = 'catalogs-job-history-download';

  public execute = async (instance: Instance): Promise<JobSearchResult | undefined> =>
    instance.httpService?.downloadJobHistory(this.args.sortSettings);
}

export class ConfiguredProductsCommand extends Command<{
  configurationId: string;
  catalogId: string;
}> {
  name = 'catalog-configured-products';

  public execute = async (instance: Instance): Promise<ConfigurationItem | undefined> => {
    return instance.httpService?.getConfigurationOption(
      this.args.configurationId,
      this.args.catalogId,
    );
  };
}

export type DownloadJobCommandArgs = { jobId: string } & CatalogAnyActionCommandArgs;
export class DownloadJobCommand extends Command<DownloadJobCommandArgs> {
  name = 'catalogs-job-download';
  public execute = async (instance: Instance): Promise<any | undefined> => {
    const result: ExportActionResult | undefined = await handleResponse(
      instance,
      this.args,
      instance.httpService?.exportJobHistory(this.args.jobId),
    );
    if (!result || !result.guid) {
      throw new Error('Job download error');
    }
    const url = `${instance.getOptions().baseUrl}/v1/Catalogs/Jobs/Download?guid=${result.guid}`;
    const filename = `Export Job ${this.args.jobId}-${result.guid}.pdf`;
    await downloadFile(filename, url);
    instance.eventBus.emit('job-download-done', url);
    return;
  };
}

export type CancelJobCommandArgs = { jobId: string } & CatalogAnyActionCommandArgs;
export class CancelJobCommand extends Command<CancelJobCommandArgs> {
  name = 'catalogs-job-cancel';
  public execute = (instance: Instance): Promise<ActionResult | undefined> =>
    handleResponse(instance, this.args, instance.httpService?.cancelJob(this.args.jobId));
}

export class CatalogImportCommand extends Command<
  {
    catalogId: string;
    file: string;
    fileName: string;
  } & CatalogAnyActionCommandArgs
> {
  name = 'catalog-import';

  public execute = async (instance: Instance): Promise<any> => {
    await handleResponse(
      instance,
      this.args,
      instance.httpService?.importCatalog(this.args.catalogId, this.args.file, this.args.fileName),
    );
    return reloadCatalogs(instance);
  };
}
export interface CatalogExportCommandArgs extends CatalogAnyActionCommandArgs {
  catalogId: string;
  catalogCode: string;
  priceSettings?: CatalogExportPriceSettings;
}
export class CatalogExportCommand extends Command<CatalogExportCommandArgs, boolean> {
  name = 'catalog-export';
  public execute = async (instance: Instance): Promise<boolean> => {
    const result: CatalogExportActionResult | undefined = await handleResponse(
      instance,
      this.args,
      instance.httpService?.exportCatalog(this.args.catalogId, this.args.priceSettings),
    );
    if (!result) {
      throw new Error('Catalog export error');
    }
    if (result && result.guid && result.type === 'Download') {
      const filename = `Export ${this.args.catalogCode}.pdf`;
      const url = `${instance.getOptions().baseUrl}/v1/Catalogs/Download?guid=${result.guid}`;
      await downloadFile(filename, url);
      instance.eventBus.emit('catalog-export-done', url);
      return false;
    }
    return true;
  };
}

export class CatalogCreateNewCommand extends Command<
  {
    code: string;
    type: CatalogTypeEnum;
    description: string;
  } & CatalogAnyActionCommandArgs,
  string | undefined
> {
  name = 'catalog-create-new';
  public execute = async (instance: Instance): Promise<string | undefined> => {
    const result: CatalogActionResult | undefined = await handleResponse(
      instance,
      this.args,
      instance.httpService?.addCatalog(this.args.code, this.args.type, this.args.description),
    );

    return result?.catalog?.id;
  };
}

export class CatalogGetOptionsCommand extends Command<void> {
  name = 'catalog-get-options';
  public execute = async (instance: Instance): Promise<CatalogOptionsResult | undefined> =>
    instance.httpService?.getCatalogOptions();
}

export class CatalogUpdateCommand extends Command<
  { catalogId: string; description: string } & CatalogAnyActionCommandArgs
> {
  name = 'catalog-update';
  public execute = async (instance: Instance): Promise<void> => {
    await handleResponse(
      instance,
      this.args,
      instance.httpService?.updateCatalog(this.args.catalogId, this.args.description),
    );
  };
}

export interface CatalogCopyCommandArgs extends CatalogAnyActionCommandArgs {
  sourceCatalogId: string;
  targetCatalogId: string;
}
export class CatalogCopyCommand extends Command<CatalogCopyCommandArgs> {
  name = 'catalog-copy';
  public execute = async (instance: Instance): Promise<void | never> => {
    await handleResponse(
      instance,
      this.args,
      instance.httpService?.copyCatalog(this.args.sourceCatalogId, this.args.targetCatalogId),
    );
    return reloadCatalogs(instance);
  };
}

export interface CatalogCopyToNewCommandArgs extends CatalogAnyActionCommandArgs {
  sourceCatalogId: string;
  code: string;
  type: CatalogTypeEnum;
  description: string;
}
export class CatalogCopyToNewCommand extends Command<CatalogCopyToNewCommandArgs> {
  name = 'catalog-copy-to-new';
  public execute = async (instance: Instance): Promise<void | never> => {
    await handleResponse(
      instance,
      this.args,
      instance.httpService?.copyToNewCatalog(this.args.sourceCatalogId, this.args.code, this.args.type, this.args.description),
    );
    return reloadCatalogs(instance);
  };
}

export class CatalogDeleteCommand extends Command<
  { catalogId: string } & CatalogAnyActionCommandArgs
> {
  name = 'catalog-delete';
  public execute = async (instance: Instance): Promise<void> => {
    await handleResponse(
      instance,
      this.args,
      instance.httpService?.deleteCatalog(this.args.catalogId),
    );
    await reloadCatalogs(instance);
  };
}
export class CatalogMakeDefaultCommand extends Command<
  { catalogId: string } & CatalogAnyActionCommandArgs
> {
  name = 'catalog-set-default';
  public execute = async (instance: Instance): Promise<void> => {
    await handleResponse(
      instance,
      this.args,
      instance.httpService?.updateDefaultCatalog(this.args.catalogId, true),
    );
    return reloadCatalogs(instance);
  };
}
export class CatalogUnMakeDefaultCommand extends Command<
  { catalogId: string } & CatalogAnyActionCommandArgs
> {
  name = 'catalog-remove-default';
  public execute = async (instance: Instance): Promise<void> => {
    await handleResponse(
      instance,
      this.args,
      instance.httpService?.updateDefaultCatalog(this.args.catalogId, false),
    );
    return reloadCatalogs(instance);
  };
}
export class CatalogProductDeleteCommand extends Command<
  {
    products: CatalogProductDeleteItem[];
    catalogId: string;
  } & CatalogAnyActionCommandArgs
> {
  name = 'catalog-product-delete';
  public execute = async (instance: Instance): Promise<void> => {
    await handleResponse(
      instance,
      this.args,
      instance.httpService?.deleteCatalogProduct(this.args.products, this.args.catalogId),
    );
    await reloadProducts(instance);
  };
}

export class ImportAliasesCommand extends Command<
  {
    file: string;
    fileName: string;
  } & CatalogAnyActionCommandArgs
> {
  name = 'catalog-import-aliases';
  public execute = async (instance: Instance): Promise<void> => {
    await handleResponse(
      instance,
      this.args,
      instance.httpService?.importCatalogAliases(this.args.file, this.args.fileName),
    );
    return reloadAliases(instance);
  };
}
export class ExportAliasesCommand extends Command<CatalogAnyActionCommandArgs, boolean> {
  name = 'catalog-export-aliases';
  public execute = async (instance: Instance): Promise<boolean> => {
    const result: ExportActionResult | undefined = await handleResponse(
      instance,
      this.args,
      instance.httpService?.exportCatalogAliases(),
    );
    if (!result) {
      throw new Error('Catalog aliases export error');
    }
    if (result && result.guid) {
      const filename = `Export aliases ${result.guid}.pdf`;
      const url = `${instance.getOptions().baseUrl}/v1/Catalogs/Aliases/Download?guid=${
        result.guid
      }`;
      await downloadFile(filename, url);
      instance.eventBus.emit('catalog-export-aliases-done', url);
      return false;
    }
    return true;
  };
}
export interface AddAliasesCommandArgs extends CatalogAnyActionCommandArgs {
  aliases: AliasAddItem[];
  doNotHandleErrors?: boolean;
}
export class AddAliasesCommand extends Command<AddAliasesCommandArgs, AliasActionResult> {
  name = 'catalog-add-aliases';

  public execute = async (instance: Instance): Promise<AliasActionResult | undefined> => {
    if (this.args.doNotHandleErrors === true) {
      let result: AliasActionResult | undefined;
      try {
        instance.store.data.isLoadingData = true;
        result = await instance.httpService?.addAliases(this.args.aliases);
      } catch (error) {
        //
      }
      instance.store.data.isLoadingData = false;
      return result;
    }
    return handleResponse(instance, this.args, instance.httpService?.addAliases(this.args.aliases));
  };
}

export interface DeleteAliasesCommandArgs extends CatalogAnyActionCommandArgs {
  aliases: AliasDeleteItem[];
  doNotHandleErrors?: boolean;
}
export class DeleteAliasesCommand extends Command<DeleteAliasesCommandArgs, ActionResult> {
  name = 'catalog-delete-aliases';

  public execute = async (instance: Instance): Promise<ActionResult | undefined> => {
    if (this.args.doNotHandleErrors === true) {
      let result: ActionResult | undefined;
      try {
        instance.store.data.isLoadingData = true;
        result = await instance.httpService?.deleteAliases(this.args.aliases);
      } catch (error) {
        //
      }
      instance.store.data.isLoadingData = false;
      return result;
    }
    return handleResponse(
      instance,
      this.args,
      instance.httpService?.deleteAliases(this.args.aliases),
    );
  };
}

export class AddNotificationCommand extends Command<INotification> {
  name = 'notification-show';
  public execute = async (instance: Instance): Promise<void> => {
    let showDelay = 0;
    let dismissAfter: number | undefined = this.args.dismissAfter;

    if (instance.store.notifications.length) {
      instance.store.notifications = [];
      showDelay = 1000;
    }

    if (!dismissAfter && dismissAfter !== 0 && this.args.type !== NotificationType.danger) {
      dismissAfter = 6000;
      instance.store.notifications = [];
    }

    if (dismissAfter && dismissAfter !== 0) {
      setTimeout(() => {
        const index = instance.store.notifications.indexOf(this.args);
        if (index !== -1) {
          instance.store.notifications.splice(index, 1);
        }
      }, this.args.dismissAfter);
    }
    setTimeout(() => {
      return instance.store.notifications.push(this.args);
    }, showDelay);
  };
}

export class DeleteNotificationCommand extends Command<INotification> {
  name = 'notification-hide';
  public execute = async (instance: Instance): Promise<void> => {
    const index = instance.store.notifications.indexOf(this.args);
    if (index !== -1) {
      instance.store.notifications.splice(index, 1);
    }
  };
}

export class CatalogActionCompleted extends Command<void> {
  name = 'catalog-action-completed';
  public execute = async (instance: Instance) =>
    Object.assign(instance.store.data.actions, {
      current: null,
      data: null,
    });
}

const setCatalogAction = async (
  instance: Instance,
  current: CatalogAction,
  data: CatalogAnyActionData,
) =>
  Object.assign(instance.store.data.actions, {
    current,
    data,
  });

export class CatalogEditActionCommand extends Command<CatalogEditActionData> {
  name = 'catalog-edit-action';
  public execute = async (instance: Instance) =>
    setCatalogAction(instance, CatalogAction.edit, this.args);
}

export class CatalogCopyActionCommand extends Command<CatalogCopyActionData> {
  name = 'catalog-copy-action';
  public execute = async (instance: Instance) =>
    setCatalogAction(instance, CatalogAction.copy, this.args);
}

export class CatalogImportActionCommand extends Command<CatalogImportActionData> {
  name = 'catalog-import-action';
  public execute = async (instance: Instance) =>
    setCatalogAction(instance, CatalogAction.import, this.args);
}

export class CatalogExportActionCommand extends Command<CatalogExportActionData> {
  name = 'catalog-export-action';
  public execute = async (instance: Instance) =>
    setCatalogAction(instance, CatalogAction.export, this.args);
}

export class CatalogDeleteActionCommand extends Command<CatalogDeleteActionData> {
  name = 'catalog-delete-action';
  public execute = async (instance: Instance) =>
    setCatalogAction(instance, CatalogAction.delete, this.args);
}

export class CatalogHistoryActionCommand extends Command<CatalogHistoryActionData> {
  name = 'catalog-history-action';
  public execute = async (instance: Instance) =>
    setCatalogAction(instance, CatalogAction.history, this.args);
}

export class CatalogNewActionCommand extends Command<CatalogNewActionData> {
  name = 'catalog-new-action';
  public execute = async (instance: Instance) =>
    setCatalogAction(instance, CatalogAction.createNew, this.args);
}
export class CatalogProductEditActionCommand extends Command<CatalogProductEditActionData> {
  name = 'catalog-product-edit-action';
  public execute = async (instance: Instance) =>
    setCatalogAction(instance, CatalogAction.productEdit, this.args);
}
export class CatalogProductCopyActionCommand extends Command<CatalogProductCopyActionData> {
  name = 'catalog-product-copy-action';
  public execute = async (instance: Instance) =>
    setCatalogAction(instance, CatalogAction.productCopy, this.args);
}
export class CatalogProductDeleteActionCommand extends Command<CatalogProductDeleteActionData> {
  name = 'catalog-product-delete-action';
  public execute = async (instance: Instance) =>
    setCatalogAction(instance, CatalogAction.productDelete, this.args);
}
export class CatalogErrorsActionCommand extends Command<CatalogErrorsActionData> {
  name = 'catalog-errors-action';
  public execute = async (instance: Instance) =>
    setCatalogAction(instance, CatalogAction.errors, this.args);
}

export class AliasCreateNewActionCommand extends Command<AliasCreateNewActionData> {
  name = 'alias-create-new-action';
  public execute = async (instance: Instance) =>
    setCatalogAction(instance, CatalogAction.aliasCreateNew, this.args);
}

export class AliasEditActionCommand extends Command<AliasEditActionData> {
  name = 'alias-edit-action';
  public execute = async (instance: Instance) =>
    setCatalogAction(instance, CatalogAction.aliasEdit, this.args);
}

export class AliasDeleteActionCommand extends Command<AliasDeleteActionData> {
  name = 'alias-delete-action';
  public execute = async (instance: Instance) =>
    setCatalogAction(instance, CatalogAction.aliasDelete, this.args);
}

export class AliasImportActionCommand extends Command<AliasImportActionData> {
  name = 'alias-import-action';
  public execute = async (instance: Instance) =>
    setCatalogAction(instance, CatalogAction.aliasImport, this.args);
}

export class AliasExportActionCommand extends Command<AliasExportActionData> {
  name = 'alias-export-action';
  public execute = async (instance: Instance) =>
    setCatalogAction(instance, CatalogAction.aliasExport, this.args);
}

export class GetAllCatalogsCommand extends Command<void> {
  name = 'catalogs-fetch';
  public execute = async (instance: Instance): Promise<CatalogItem[]> => {
    const result = await instance.httpService?.getAllCatalogs();
    if (!result || !result.items) {
      return [];
    }
    return result.items;
  };
}

export class GetCatalogByIdCommand extends Command<{ catalogId: string }> {
  name = 'catalog-fetch-by-id';
  public execute = async (instance: Instance): Promise<CatalogItem | undefined> =>
    instance.httpService?.getCatalogById(this.args.catalogId);
}

export class CatalogListActionCommand extends Command<{
  menuItem: IMenuItem<void>;
}> {
  name = 'catalog-list-action';
  public execute = async (): Promise<void> => executePlaceholder(this.name);
}
export class CatalogItemActionCommand extends Command<{
  menuItem: IMenuItem<CatalogItem>;
  catalog: CatalogItem;
}> {
  name = 'catalog-item-action';
  public execute = async (): Promise<void> => executePlaceholder(this.name);
}
export class ProductListActionCommand extends Command<{
  menuItem: IMenuItem<void>;
}> {
  name = 'product-list-action';
  public execute = async (): Promise<void> => executePlaceholder(this.name);
}
export class ProductItemActionCommand extends Command<{
  menuItem: IMenuItem<CatalogProductItem>;
  product: CatalogProductItem;
  catalog: Catalog;
}> {
  name = 'product-item-action';
  public execute = async (): Promise<void> => executePlaceholder(this.name);
}
export class AliasListActionCommand extends Command<{
  menuItem: IMenuItem<void>;
}> {
  name = 'alias-list-action';
  public execute = async (): Promise<void> => executePlaceholder(this.name);
}
export class AliasItemActionCommand extends Command<{
  menuItem: IMenuItem<CatalogProductItem>;
  alias: CatalogProductItem;
}> {
  name = 'alias-item-action';
  public execute = async (): Promise<void> => executePlaceholder(this.name);
}

export class CommandExecutor {
  constructor(private instance: Instance) {}

  public async execute<ReturnType = unknown>(
    command: ICommand<Instance, ReturnType>,
  ): Promise<ReturnType | undefined> {
    const commandInterceptor = this.instance.store.options.commandInterceptor;

    if (commandInterceptor) {
      try {
        // we don't want to expose execute function nor let external app to modify args
        // we clone the command exposed externally
        const commandExternal = _cloneDeep(_omit(command, ['execute']));

        await commandInterceptor(commandExternal as ICommand<IInstance, ReturnType>);
        if (commandExternal.isHandled) {
          return Promise.reject();
        }

        return command?.execute(this.instance);
      } catch (e) {
        this.instance.logger.log('Command cancelled', command);
      }
    } else {
      return command?.execute(this.instance);
    }
  }
}
