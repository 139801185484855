
import { defineComponent, ref, computed } from 'vue';
import Confirmation from '@/common/components/Confirmation.vue';
import InjectStyles from '@/common/components/InjectStyles.vue';
import { setupComponent } from '@/catalogs/composables/setupComponent';
import ProductEditModal from '@/catalogs/components/products/ProductEditModal.vue';
import ProductCopyModal from '@/catalogs/components/products/ProductCopyModal.vue';
import {
  CatalogActionCompleted,
  CatalogProductDeleteCommand,
} from '@/catalogs/api/runtime/CommandExecutor';
import { CatalogProductItem } from '@/common/services/swagger/index.defs';
import { ComponentName } from '@/catalogs/api/configuration/components/ComponentName';
import { CatalogAction } from '@/catalogs/api/runtime/CatalogAction';
import { attrValue } from '@/common/helpers/productAttributes';
// Used in template
/* eslint-disable */
import {
  CatalogProductEditActionData,
  CatalogProductDeleteActionData,
  CatalogProductCopyActionData,
} from '@/catalogs/api/runtime/CatalogActionData';
/* eslint-enable */

export default defineComponent({
  name: ComponentName.catalogProductsActionModals,
  components: { ProductEditModal, ProductCopyModal, Confirmation, InjectStyles },
  props: {
    instanceId: String,
  },
  setup(props) {
    const root = ref<HTMLElement>();
    const { componentName, instance, isReady, isWebComponent, store, t } = setupComponent(
      root,
      props.instanceId,
    );
    const actions = computed(() => instance.value?.store.data.actions);
    const currentActionName = computed(() => actions.value?.current);
    const currentActionData = computed(() => actions.value?.data);
    const getProductDisplayName = (product: CatalogProductItem) =>
      (product.attributes['#DisplayName']?.values ?? [
        {
          text: product.productId,
        },
      ])[0].text;
    const onActionCompleted = async () => {
      try {
        instance.value?.execute(new CatalogActionCompleted());
      } catch (error) {
        // Ignore error
      }
    };
    const onCatalogProductDelete = async () => {
      const currentActions = actions.value;
      if (!currentActions?.current || currentActions.current !== CatalogAction.productDelete) {
        return;
      }
      const catalogProductForDeletion: CatalogProductDeleteActionData =
        currentActions.data as CatalogProductDeleteActionData;
      if (
        !catalogProductForDeletion ||
        !catalogProductForDeletion.product ||
        !catalogProductForDeletion.product.productId ||
        !catalogProductForDeletion.catalog ||
        !catalogProductForDeletion.catalog.id
      ) {
        return;
      }
      try {
        await instance.value?.execute(
          new CatalogProductDeleteCommand({
            onSuccessMessage: t('CatalogProductDeleteSuccess'),
            products: [
              {
                productId: catalogProductForDeletion.product.productId,
              },
            ],
            catalogId: catalogProductForDeletion.catalog.id,
          }),
        );
      } catch (error) {
        // Ignore Error
      }
      onActionCompleted();
    };

    return {
      root,
      store,
      isReady,
      instance,
      CatalogAction,
      componentName,
      isWebComponent,
      currentActionName,
      currentActionData,
      t,
      attrValue,
      onActionCompleted,
      getProductDisplayName,
      onCatalogProductDelete,
    };
  },
});
