
import { defineComponent, onBeforeUnmount, watch } from 'vue';
import _debounce from 'lodash/debounce';
import _uniqueId from 'lodash/uniqueId';
import PIS from '@/common/api/PIS';
import { SearchTypeEnum, SortFieldEnum, TreeTypeEnum } from '@/common/services/swagger/index.defs';
import { ComponentName } from '@/products/api/configuration/components/ComponentName';
import { IOptions } from '../api/runtime/IOptions';
import { DisplayMode, IRouteData } from '../api/runtime/IRouteData';
import { Instance } from '../api/Instance';
import LoggerService from '@/common/services/LoggerService';

const INSTANCE_NAME_PREFIX = 'pisProductsInstance_';

export default defineComponent({
  name: ComponentName.instance,

  props: {
    // Allow to configure the APP before displaying it
    // Without this property developers will need to use `instance.init({})` on their own.
    // We use `String` type because adding empty `init` attribute in react apps converts it into `init="true"`.
    init: [Boolean, String],
    instanceId: String,
    baseUrl: String,
    accessToken: String,

    styles: String,

    settingAppCode: String,
    settingLangCode: String,
    settingCountryCode: String,
    settingInternalUser: Boolean,
    settingShowPartsTree: Boolean,
    settingAgmListCode: String,
    settingAnonymousUser: Boolean,

    // App
    settingAppAllReplacements: Boolean,
    settingAppAssocGroups: String,
    settingAppCustomerId: String,
    settingAppCustomers: String,
    settingAppFrontEndFilter: String,
    settingAppGenericizeAttName: Boolean,
    settingAppSalesUnitCode: String,
    settingAppSellingUnitForLocalProd: String,
    settingAppTableRowMatching: Boolean,
    settingRelatedProducts: Boolean,
    settingAppStandardizationBody: String,

    // Alias
    settingAliasDomain: String,
    settingAliasOrgIdGlobal: String,
    settingAliasOrgIdLocal: String,
    settingAliasCustomers: String,

    // Filter
    settingFilterDataOwners: String,
    settingFilterBuId: String,
    settingFilterPuId: String,
    settingFilterRestrictedCids: String,
    settingFilterSalesErp: String,
    settingFilterSuId: String,
    settingFilterProductIdentifiers: String,
    settingFilterSortByProductIdentifiers: Boolean,
    settingFilterInclusionCriteria: String,
    settingFilterExclusionCriteria: String,
    settingFilterCategoryInclusionCriteria: String,
    settingFilterCategoryExclusionCriteria: String,

    // Favorite Categories
    settingFavoriteProductCids: String,
    settingFavoritePartCids: String,

    // Favorite Views
    settingFavoriteViewsIds: String,

    // AttributesTable
    settingMaxAttributeValuesVisible: String,

    // Element visibility
    evShowConfigurator: Boolean,
    evShowDocumentLinks: Boolean,
    evHideBreadcrumbs: Boolean,
    evHideDetailCategories: Boolean,
    evHideDownloads: Boolean,
    evHideImages: Boolean,
    evHideInitialSearchResults: Boolean,
    evHideRelatedLinkImages: Boolean,
    evHideReplacements: Boolean,
    evHideSearchCategories: Boolean,
    evHideWhereUsed: Boolean,
    evDisplayMode: String,
    evShowFavoriteCategories: Boolean,
    evShowFavoriteViews: Boolean,
    evHideDetailsHeader: Boolean,
    evHideDetailsBackBtn: Boolean,
    evShowPrintToPdfBtn: Boolean,
    evShowFeedbackBtn: Boolean,
    evShowPrintViewBtn: Boolean,
    evShowRelatedLinks: Boolean,
    evDisplayModes: String,

    // Filters
    filtersVisibleFromNodeLevel: Number,
    filtersExpandedFromNodeLevel: Number,
    filtersMaxFiltersVisible: Number,
    filtersExpandAllVisible: Boolean,
    filtersCollapseAllVisible: Boolean,

    // Search
    searchText: String,
    searchType: String,
    searchCid: String,
    productId: String,

    // Compare
    compareCompact: Boolean,
    compareCategoriesVisible: Boolean,
    compareAttributeCodes: String,
    compareImageSize: String,
    compareAttrColWidth: String,
    compareValColWidth: String,
    compareImageVisible: String,
    compareImageHideOnScroll: String,

    // selection
    selectionVisible: Boolean,
    selectionPersist: Boolean,
    selectionSelectAllVisible: Boolean,
    selectionDeselectAllVisible: Boolean,
    selectionCountVisible: Boolean,
    itemsTableSelectable: Boolean,
    itemsListSelectable: Boolean,
    itemsGridSelectable: Boolean,

    // details
    relationshipFiltering: String,
    relationshipFilteringMaxFilters: Number,

    // search
    searchDisableAutocomplete: Boolean,
    searchAutoCategoryNavigation: Boolean,
    searchAutoDetailsNavigation: Boolean,
    searchShowProductImages: Boolean,
    searchShowCategoryImages: Boolean,
    searchCategorySearch: Boolean,
    searchCategoryKeywordSearch: Boolean,
    searchCategoryMaxMatches: { type: Number, default: 2 },
    // Component specific
    dashboard: Boolean,
    inheritStyles: [Boolean, String],
  },

  setup(props) {
    const instance = PIS.Products.getInstance(
      props.instanceId ?? _uniqueId(INSTANCE_NAME_PREFIX),
    ) as Instance;

    const getInstanceId = () => instance?.id;
    const getInstance = () => instance;
    const requiredPropNames = ['accessToken', 'settingAppCode'];

    const handlePropertyChanged = () => {
      if (instance?.isInitialized()) {
        // TODO: we need to update props, app settings will be watched

        if (props.accessToken) {
          instance.store.options.accessToken = props.accessToken;
        }
        const appOptions = instance.store.options.application;

        if (props.settingLangCode && appOptions) {
          appOptions.langCode = props.settingLangCode;
        }

        return;
      }

      if (props.dashboard) {
        instance.props['dashboard'] = true;
      }

      // display modes - keep order reflecting passed setting
      const displayModesSet = toArray(props.evDisplayModes || undefined);
      const displayModesAllowed = Object.values(DisplayMode);

      let displayModes: DisplayMode[] = [];

      if (displayModesSet && displayModesSet.length) {
        displayModesSet.forEach((dm) => {
          if (displayModesAllowed.includes(DisplayMode[dm])) {
            displayModes.push(DisplayMode[dm]);
          }
        });
      } else {
        displayModes = [...displayModesAllowed];
      }

      const settings = {
        baseUrl: props.baseUrl,
        accessToken: props.accessToken,
        application: {
          appCode: props.settingAppCode,
          langCode: props.settingLangCode,
          countryCode: props.settingCountryCode,
          internalUser: props.settingInternalUser,
          anonymousUser: props.settingAnonymousUser,
          treeType: props.settingShowPartsTree ? TreeTypeEnum.Parts : TreeTypeEnum.Products,
          agmListCode: props.settingAgmListCode,
          aliases: {
            domain: props.settingAliasDomain,
            orgIdGlobal: props.settingAliasOrgIdGlobal,
            orgIdLocal: props.settingAliasOrgIdLocal,
            customers: parseCustomers(props.settingAliasCustomers),
          },
          appSpecific: {
            allReplacements: props.settingAppAllReplacements,
            associatedGroups: toArray(props.settingAppAssocGroups),
            customerId: props.settingAppCustomerId,
            customers: parseCustomers(props.settingAppCustomers),
            frontEndFilter: toArray(props.settingAppFrontEndFilter),
            genericizeAttName: props.settingAppGenericizeAttName ?? false,
            salesUnitCode: props.settingAppSalesUnitCode,
            sellingUnitForLocalProducts: toArray(props.settingAppSellingUnitForLocalProd),
            tableRowMatching: props.settingAppTableRowMatching ?? false,
            relatedProducts: props.settingRelatedProducts ?? false,
            standardizationBody: toArray(props.settingAppStandardizationBody),
          },
          filters: {
            dataOwners: toArray(props.settingFilterDataOwners),
            buId: toArray(props.settingFilterBuId),
            puId: toArray(props.settingFilterPuId),
            suId: toArray(props.settingFilterSuId),
            restrictedCids: toArray(props.settingFilterRestrictedCids),
            salesErp: toBoolUndefined(props.settingFilterSalesErp),
            productIdentifiers: toArray(props.settingFilterProductIdentifiers),
            sortByProductIdentifiers: props.settingFilterSortByProductIdentifiers ?? false,
            inclusionCriteria: parseCriteria(props.settingFilterInclusionCriteria),
            exclusionCriteria: parseCriteria(props.settingFilterExclusionCriteria),
            categoryInclusionCriteria: parseCriteria(props.settingFilterCategoryInclusionCriteria),
            categoryExclusionCriteria: parseCriteria(props.settingFilterCategoryExclusionCriteria),
          },
        },
        hideInitialSearchResults: props.evHideInitialSearchResults == true,
        components: {
          common: {
            hideDocumentLinks: props.evShowDocumentLinks != true,
            hideReplacements: props.evHideReplacements == true,
            hideWhereUsed: props.evHideWhereUsed == true,
            maxAttributeValuesVisible: toNumber(props.settingMaxAttributeValuesVisible),
          },
          searchToolbar: {
            displayModes,
          },
          downloads: {
            visible: props.evHideDownloads != true,
          },
          configurators: {
            visible: props.evShowConfigurator,
          },
          detailsClassifications: {
            visible: props.evHideDetailCategories != true,
          },
          detailsGallery: {
            visible: props.evHideImages != true,
          },
          details: {
            relationships: {
              filtering: props.relationshipFiltering,
              filteringMaxAttributesVisible: props.relationshipFilteringMaxFilters,
            },
          },
          searchCategories: {
            visible: props.evHideSearchCategories != true,
          },
          searchForm: {
            useAutocomplete: props.searchDisableAutocomplete != true,
            autoCategoryNavigation: props.searchAutoCategoryNavigation,
            autoDetailsNavigation: props.searchAutoDetailsNavigation,
            showProductImages: props.searchShowProductImages,
            showCategoryImages: props.searchShowCategoryImages,
            categorySearch: props.searchCategorySearch,
            categoryKeywordSearch: props.searchCategoryKeywordSearch,
            maxCategoryMatches: props.searchCategoryMaxMatches,
          },
          breadcrumbs: {
            visible: props.evHideBreadcrumbs != true,
          },
          relatedLinks: {
            visible: props.evShowRelatedLinks,
            showImages: props.evHideRelatedLinkImages != true,
          },
          favoriteCategories: {
            visible: props.evShowFavoriteCategories,
            productCids: toArray(props.settingFavoriteProductCids),
            partCids: toArray(props.settingFavoritePartCids),
          },
          favoriteViews: {
            visible: props.evShowFavoriteViews,
            savedViewIds: toArray(props.settingFavoriteViewsIds),
          },
          detailsHeader: {
            visible: !props.evHideDetailsHeader,
            showBackBtn: !props.evHideDetailsBackBtn,
            showFeedbackBtn: props.settingInternalUser && props.evShowFeedbackBtn,
            showPrintToPdfBtn: props.evShowPrintToPdfBtn,
            showPrintViewBtn: props.evShowPrintViewBtn,
          },
          compare: {
            visible: true,
            attrColWidth: props.compareAttrColWidth,
            valColWidth: props.compareValColWidth,
            attributeCodes: toArray(props.compareAttributeCodes),
            imageSize: props.compareImageSize,
            compactMode: props.compareCompact,
            categoriesVisible: props.compareCategoriesVisible,
            imageVisible: toBool(props.compareImageVisible),
            imageHideOnScroll: toBool(props.compareImageHideOnScroll),
          },
          selection: {
            visible: props.selectionVisible,
            countVisible: props.selectionCountVisible,
            persist: props.selectionPersist,
            selectAllVisible: props.selectionSelectAllVisible,
            deselectAllVisible: props.selectionDeselectAllVisible,
          },
          itemsTable: {
            selectable: props.itemsTableSelectable,
          },
          itemsList: {
            selectable: props.itemsListSelectable,
          },
          itemsGrid: {
            selectable: props.itemsGridSelectable,
          },
          searchFilters: {
            maxFiltersVisible: props.filtersMaxFiltersVisible,
            expandedFromNodeLevel: props.filtersExpandedFromNodeLevel,
            visibleFromNodeLevel: props.filtersVisibleFromNodeLevel,
            expandAllVisible: props.filtersExpandAllVisible,
            collapseAllVisible: props.filtersCollapseAllVisible,
          },
        },
        styles: props.styles ? JSON.parse(props.styles) : undefined,
        inheritStyles: !!props.inheritStyles,
      } as IOptions;

      const route = {
        searchText: props.searchText,
        searchType: props.searchType || SearchTypeEnum.WithAllWords,
        productId: props.productId,
        cid: props.searchCid,
        view: props.productId ? 'detail' : 'search',
        displayMode:
          props.evDisplayMode && displayModes.includes(props.evDisplayMode as DisplayMode)
            ? DisplayMode[props.evDisplayMode]
            : displayModes[0],
        sortField: props.searchText ? SortFieldEnum.Relevance : SortFieldEnum.Product,
      } as IRouteData;

      (instance as any)['_routeJson'] = JSON.stringify(route, null, 4);
      (instance as any)['_optionsJson'] = JSON.stringify(settings, null, 4);

      if (props.init) {
        const missingProps = requiredPropNames.filter((propName) => !(props as any)[propName]);

        if (missingProps?.length) {
          instance?.logger.log(`Following required props are missing: ${missingProps.join(', ')}`);
        } else {
          instance?.init(settings, route);
        }
      } else {
        instance?.update(settings, route);
      }
    };

    const toArray = (val: string | undefined): string[] | undefined => {
      if (val === undefined) return undefined;

      return val
        .split(',')
        .map((chunk) => chunk?.trim())
        .filter((a) => a != null && a.length > 0);
    };

    const toNumber = (val: string | undefined): number | undefined => {
      return parseInt(val || '', 10) || undefined;
    };

    const toBoolUndefined = (val: string | undefined): boolean | undefined => {
      if (!val) return undefined;

      switch (val.toLowerCase()) {
        case 'y':
          return true;
        case 'n':
          return false;
        default:
          return undefined;
      }
    };

    const toBool = (val: string | undefined): boolean => {
      if (!val) return true;

      switch (val.toLowerCase()) {
        case 'true':
          return true;
        case 'false':
          return false;
        default:
          return true;
      }
    };

    const parseCriteria = (val?: string) => {
      if (!val || val.length === 0) {
        return undefined;
      }

      try {
        return JSON.parse(val) as any;
      } catch (error) {
        LoggerService.log('Invalid criteria: ' + val, error);
        return undefined;
      }
    };

    const parseCustomers = (val?: string) => {
      if (!val || val.length === 0) {
        return undefined;
      }

      try {
        return JSON.parse(val) as any;
      } catch (error) {
        LoggerService.log('Invalid customers: ' + val, error);
        return undefined;
      }
    };

    onBeforeUnmount(() => {
      instance?.destroy();
    });

    watch(props, _debounce(handlePropertyChanged, 50), { immediate: true });

    return {
      getInstanceId,
      getInstance,
    };
  },
});
