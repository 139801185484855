import { Ref } from 'vue';
import { Instance } from '@/catalogs/api/Instance';
import { CatalogItem, CatalogProductItem } from '@/common/services/swagger/index.defs';
import {
  AliasDeleteActionCommand,
  AliasEditActionCommand,
  AliasExportActionCommand,
  AliasImportActionCommand,
  AliasItemActionCommand,
  AliasListActionCommand,
  CatalogCopyActionCommand,
  CatalogDeleteActionCommand,
  CatalogEditActionCommand,
  CatalogExportActionCommand,
  CatalogHistoryActionCommand,
  CatalogImportActionCommand,
  CatalogItemActionCommand,
  CatalogListActionCommand,
  CatalogMakeDefaultCommand,
  CatalogNewActionCommand,
  CatalogProductCopyActionCommand,
  CatalogProductDeleteActionCommand,
  CatalogProductEditActionCommand,
  CatalogUnMakeDefaultCommand,
  GetCatalogByIdCommand,
  ProductItemActionCommand,
  ProductListActionCommand,
} from '@/catalogs/api/runtime/CommandExecutor';
import { TranslateFunc } from './setupComponent';
import { InternalMenuActions } from '../api/configuration/DefaultOptions';
import { IMenuItem } from '../api/configuration/components/IExportMenuOptions';

export default function useCatalogActions(instance: Ref<Instance>, t: TranslateFunc) {
  const catalogListActions = {
    [InternalMenuActions.createNew]: () => new CatalogNewActionCommand(),
    [InternalMenuActions.history]: () => new CatalogHistoryActionCommand(),
  };
  const catalogItemActions = {
    [InternalMenuActions.makeDefault]: (catalog: CatalogItem) =>
      new CatalogMakeDefaultCommand({
        catalogId: catalog.id,
        onSuccessMessage: t('CatalogSetAsDefaultSuccess'),
      }),
    [InternalMenuActions.unmakeDefault]: (catalog: CatalogItem) =>
      new CatalogUnMakeDefaultCommand({
        catalogId: catalog.id,
        onSuccessMessage: t('CatalogUnsetAsDefaultSuccess'),
      }),
    [InternalMenuActions.edit]: (catalog: CatalogItem) => new CatalogEditActionCommand({ catalog }),
    [InternalMenuActions.copy]: (catalog: CatalogItem) => new CatalogCopyActionCommand({ catalog }),
    [InternalMenuActions.import]: (catalog: CatalogItem) =>
      new CatalogImportActionCommand({ catalog }),
    [InternalMenuActions.export]: (catalog: CatalogItem) =>
      new CatalogExportActionCommand({ catalog }),
    [InternalMenuActions.delete]: (catalog: CatalogItem) =>
      new CatalogDeleteActionCommand({ catalog }),
  };
  const productItemActions = {
    [InternalMenuActions.productEdit]: (product: CatalogProductItem, catalog: CatalogItem) =>
      new CatalogProductEditActionCommand({ product, catalog }),
    [InternalMenuActions.productCopy]: (product: CatalogProductItem, catalog: CatalogItem) =>
      new CatalogProductCopyActionCommand({ product, catalog }),
    [InternalMenuActions.productDelete]: (product: CatalogProductItem, catalog: CatalogItem) =>
      new CatalogProductDeleteActionCommand({ product, catalog }),
  };
  const aliasListActions = {
    [InternalMenuActions.aliasImport]: () => new AliasImportActionCommand(),
    [InternalMenuActions.aliasExport]: () => new AliasExportActionCommand(),
  };
  const aliasItemActions = {
    [InternalMenuActions.aliasEdit]: (alias: CatalogProductItem) =>
      new AliasEditActionCommand({ alias }),
    [InternalMenuActions.aliasDelete]: (alias: CatalogProductItem) =>
      new AliasDeleteActionCommand({ aliases: alias.aliases || [] }),
  };

  const onCatalogItemMenuAction = async (
    menuItem: IMenuItem<CatalogItem>,
    catalog: CatalogItem,
  ): Promise<unknown> => {
    const command =
      menuItem.code && catalogItemActions[menuItem.code]
        ? catalogItemActions[menuItem.code](catalog)
        : new CatalogItemActionCommand({ menuItem, catalog });
    try {
      return await instance.value.execute<unknown>(command);
    } catch (error) {
      // Ignore error
    }
  };

  const onCatalogListMenuAction = async (menuItem: IMenuItem<void>): Promise<unknown> => {
    const command =
      menuItem.code && catalogListActions[menuItem.code]
        ? catalogListActions[menuItem.code]()
        : new CatalogListActionCommand({ menuItem });
    try {
      return await instance.value.execute<unknown>(command);
    } catch (error) {
      // Ignore error
    }
  };

  const onProductListMenuAction = async (menuItem: IMenuItem<void>) => {
    try {
      return await instance.value.execute(new ProductListActionCommand({ menuItem }));
    } catch (error) {
      // Ignore error
    }
  };

  const onProductItemMenuAction = async (
    menuItem: IMenuItem<CatalogProductItem>,
    product: CatalogProductItem,
    catalogId: string,
  ): Promise<unknown> => {
    const catalog: CatalogItem | undefined = (await instance.value.execute(
      new GetCatalogByIdCommand({ catalogId }),
    )) as CatalogItem | undefined;
    if (!catalog) {
      throw new Error(`Catalog "${catalogId}" not found!`);
    }
    const command =
      menuItem.code && productItemActions[menuItem.code]
        ? productItemActions[menuItem.code](product, catalog)
        : new ProductItemActionCommand({ menuItem, product, catalog });
    try {
      return await instance.value.execute<unknown>(command);
    } catch (error) {
      // Ignore error
    }
  };

  const onAliasListMenuAction = async (menuItem: IMenuItem<void>) => {
    try {
      const command =
        menuItem.code && aliasListActions[menuItem.code]
          ? aliasListActions[menuItem.code]()
          : new AliasListActionCommand({ menuItem });
      return await instance.value.execute<unknown>(command);
    } catch (error) {
      // Ignore error
    }
  };

  const onAliasItemMenuAction = async (
    menuItem: IMenuItem<CatalogProductItem>,
    alias: CatalogProductItem,
  ): Promise<unknown> => {
    const command =
      menuItem.code && aliasItemActions[menuItem.code]
        ? aliasItemActions[menuItem.code](alias)
        : new AliasItemActionCommand({ menuItem, alias });
    try {
      return await instance.value.execute<unknown>(command);
    } catch (error) {
      // Ignore error
    }
  };

  return {
    onCatalogItemMenuAction,
    onCatalogListMenuAction,
    onProductListMenuAction,
    onProductItemMenuAction,
    onAliasListMenuAction,
    onAliasItemMenuAction,
  };
}
