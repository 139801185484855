import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = {
  key: 0,
  class: "mt-3"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "d-inline-block me-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_LinkSecondary = _resolveComponent("LinkSecondary")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["pis-component", _ctx.componentName]),
    part: _ctx.componentName
  }, [
    (_ctx.isReady && _ctx.componentOptions.visible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store!.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          (_ctx.filtersApplied.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("small", _hoisted_4, _toDisplayString(_ctx.t('AppliedFilters')) + ":", 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filtersApplied, (filter, fIndex) => {
                  return (_openBlock(), _createElementBlock(_Fragment, null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filter.values, (value, vIndex) => {
                      return (_openBlock(), _createBlock(_component_Badge, {
                        class: "me-2 my-1 py-2",
                        removable: "",
                        key: `${fIndex}${vIndex}`,
                        title: `${filter.attributeName ?? filter.attributeCode}: ${value.name}`,
                        "remove-title": _ctx.t('RemoveBadgeTitle'),
                        onRemove: ($event: any) => (_ctx.excludeFilter(filter, `${value.name}␡${value.code}`))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.truncateText(filter.attributeName! ?? filter.attributeCode)) + ": " + _toDisplayString(_ctx.truncateText(value.name)), 1)
                        ]),
                        _: 2
                      }, 1032, ["title", "remove-title", "onRemove"]))
                    }), 128))
                  ], 64))
                }), 256)),
                _createVNode(_component_LinkSecondary, {
                  icon: "cancel",
                  class: "d-inline-flex align-middle",
                  onClick: _ctx.resetAll
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('ClearAll')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}