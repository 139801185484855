import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "row gx-5 gy-3",
  style: {"height":"calc(100vh - 200px)","max-height":"800px"}
}
const _hoisted_2 = {
  key: 0,
  class: "mb-3"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 0,
  class: "text-center py-4"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "mb-4" }
const _hoisted_8 = { class: "list-group-item list-group-item-action px-1" }
const _hoisted_9 = { class: "form-check d-flex align-items-start" }
const _hoisted_10 = ["checked", "name", "onInput"]
const _hoisted_11 = ["textContent"]
const _hoisted_12 = {
  key: 1,
  class: "pis-message"
}
const _hoisted_13 = {
  key: 1,
  class: "pis-message pis-message-error"
}
const _hoisted_14 = {
  key: 2,
  class: "h-100 d-flex align-items-center"
}
const _hoisted_15 = {
  ref: "modalFooter",
  class: "w-100 d-flex align-items-center justify-content-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormInputClearable = _resolveComponent("FormInputClearable")!
  const _component_FiltersList = _resolveComponent("FiltersList")!
  const _component_Message = _resolveComponent("Message")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_ScrollableList = _resolveComponent("ScrollableList")!
  const _component_LinkSecondary = _resolveComponent("LinkSecondary")!
  const _component_Arrow = _resolveComponent("Arrow")!
  const _component_Btn = _resolveComponent("Btn")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    show: _ctx.show,
    title: _ctx.t('Filters', true),
    "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:show'))),
    onResize: _ctx.modalResize,
    lg: ""
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_15, [
        _createVNode(_component_LinkSecondary, {
          icon: "cancel",
          onClick: _ctx.clear,
          class: "ext-clear"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('ClearFilters', true)), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        (_ctx.isMobile && _ctx.currentFilterAttrCode)
          ? (_openBlock(), _createBlock(_component_Btn, {
              key: 0,
              class: "ms-4 ext-reset",
              onClick: _withModifiers(_ctx.resetCurrentFilterAttrCode, ["prevent"])
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Arrow, {
                  dir: "left",
                  class: "me-2"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.t('ApproveFilterValuesSelected')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true),
        (!_ctx.isMobile || !_ctx.currentFilterAttrCode)
          ? (_openBlock(), _createBlock(_component_Btn, {
              key: 1,
              icon: "check-mark",
              class: "ms-4 ext-apply",
              disabled: _ctx.areFiltersDirty !== true || _ctx.filteredResultCount === 0,
              "is-loading": _ctx.filteredResultCountLoading,
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('apply-filters')), ["prevent"]))
            }, {
              default: _withCtx(() => [
                (_ctx.filteredResultCount === null)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(_ctx.t('ShowResults', true)), 1)
                    ], 64))
                  : (_ctx.filteredResultCount === 0)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(_toDisplayString(_ctx.t('NoFilteringResultsFound')), 1)
                      ], 64))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                        _createTextVNode(_toDisplayString(_ctx.t('ShowNResults', true, [_ctx.filteredResultCount || 0])), 1)
                      ], 64))
              ]),
              _: 1
            }, 8, ["disabled", "is-loading"]))
          : _createCommentVNode("", true)
      ], 512)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createElementVNode("div", {
          class: _normalizeClass({ 'col-6': !_ctx.isMobile, 'col-12': _ctx.isMobile })
        }, [
          _createVNode(_component_FormInputClearable, {
            placeholder: _ctx.t('FindFilterAttribute'),
            class: "mb-4",
            "onUpdate:modelValue": _ctx.applyFilterNameSearch,
            onClear: _ctx.resetFilterNameSearch
          }, null, 8, ["placeholder", "onUpdate:modelValue", "onClear"]),
          (_ctx.filtersPromoted?.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("p", null, [
                  _createElementVNode("strong", null, _toDisplayString(_ctx.t('FiltersPromoted')), 1)
                ]),
                (_ctx.filtersPromotedSearched?.length)
                  ? (_openBlock(), _createBlock(_component_FiltersList, {
                      key: 0,
                      height: _ctx.filtersSectionHeight,
                      items: _ctx.filtersPromotedSearched,
                      "current-attribute-code": _ctx.currentFilterData?.attributeCode,
                      onItemClick: _ctx.openFilter
                    }, null, 8, ["height", "items", "current-attribute-code", "onItemClick"]))
                  : (_openBlock(), _createBlock(_component_Message, { key: 1 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('NoFiltersSearchFound')), 1)
                      ]),
                      _: 1
                    }))
              ]))
            : _createCommentVNode("", true),
          (_ctx.filtersAdditional?.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("p", null, [
                  _createElementVNode("strong", null, _toDisplayString(_ctx.filtersPromoted?.length ? _ctx.t('AdditionalFilters') : _ctx.t('AvailableFilters')) + ": ", 1)
                ]),
                (_ctx.filtersAdditionalSearched?.length)
                  ? (_openBlock(), _createBlock(_component_FiltersList, {
                      key: 0,
                      height: _ctx.filtersSectionHeight,
                      items: _ctx.filtersAdditionalSearched,
                      "current-attribute-code": _ctx.currentFilterData?.attributeCode,
                      onItemClick: _ctx.openFilter
                    }, null, 8, ["height", "items", "current-attribute-code", "onItemClick"]))
                  : (_openBlock(), _createBlock(_component_Message, { key: 1 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('NoFiltersSearchFound')), 1)
                      ]),
                      _: 1
                    }))
              ]))
            : _createCommentVNode("", true)
        ], 2), [
          [_vShow, !_ctx.isMobile || !(_ctx.currentFilterAttrCode || _ctx.filterValuesLoading)]
        ]),
        _withDirectives(_createElementVNode("div", {
          class: _normalizeClass({ 'col-6': !_ctx.isMobile, 'col-12': _ctx.isMobile })
        }, [
          (_ctx.filterValuesLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_Spinner)
              ]))
            : (_ctx.currentFilterAttrCode)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (_ctx.currentFilterValues && _ctx.currentFilterData)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createElementVNode("div", _hoisted_7, [
                          _createVNode(_component_FormInputClearable, {
                            placeholder: _ctx.t('FindFilterValue'),
                            class: "mb-4",
                            "onUpdate:modelValue": _ctx.applyFilterValueSearch,
                            onClear: _ctx.resetFilterValueSearch
                          }, null, 8, ["placeholder", "onUpdate:modelValue", "onClear"])
                        ]),
                        _createElementVNode("p", null, [
                          _createElementVNode("strong", null, _toDisplayString(_ctx.currentFilterData.attributeName) + " (" + _toDisplayString(_ctx.currentFilterValues?.length) + "): ", 1)
                        ]),
                        (_ctx.currentFilterValuesSearched?.length && _ctx.filtersModel)
                          ? (_openBlock(), _createBlock(_component_ScrollableList, {
                              key: 0,
                              class: "list-group list-group-flush",
                              items: _ctx.currentFilterValuesSearched,
                              height: _ctx.filtersSectionRightHeight
                            }, {
                              item: _withCtx(({ item }) => [
                                _createElementVNode("label", _hoisted_8, [
                                  _createElementVNode("div", _hoisted_9, [
                                    _createElementVNode("input", {
                                      type: "checkbox",
                                      class: "form-check-input me-2 flex-shrink-0",
                                      checked: 
                        _ctx.filtersModel[_ctx.currentFilterData.attributeCode][`${item.name}␡${item.code}`]
                      ,
                                      name: item.name,
                                      onInput: ($event: any) => (
                        _ctx.onValueChange(
                          $event as InputEvent,
                          _ctx.currentFilterData?.attributeCode || '',
                          `${item.name}␡${item.code}`,
                        )
                      )
                                    }, null, 40, _hoisted_10),
                                    _createElementVNode("span", {
                                      textContent: _toDisplayString(item.name),
                                      class: "form-check-label text-break user-select-none pis-label"
                                    }, null, 8, _hoisted_11),
                                    _createVNode(_component_Badge, {
                                      class: "ms-auto",
                                      textContent: _toDisplayString(item.hitCount)
                                    }, null, 8, ["textContent"])
                                  ])
                                ])
                              ]),
                              _: 1
                            }, 8, ["items", "height"]))
                          : (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(_ctx.t('NoFilterValuesSearchFound')), 1))
                      ]))
                    : (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(_ctx.t('NoFilterValues')), 1))
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  _createVNode(_component_Message, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('SelectFilter')), 1)
                    ]),
                    _: 1
                  })
                ]))
        ], 2), [
          [_vShow, !_ctx.isMobile || _ctx.currentFilterAttrCode || _ctx.filterValuesLoading]
        ])
      ])
    ]),
    _: 1
  }, 8, ["show", "title", "onResize"]))
}